.cart {
    &__body {
        display: grid;

        @media(min-width: 1201px) {
            column-gap: 49px;
            grid-template-columns: 1fr 296px;
            grid-template-rows: max-content 10px max-content;
            grid-template-areas:
                'table .'
                'total .'
                'total box'
            ;
        }

        @media(max-width: 1200px) {
            gap: 16px;
        }
    }

    &__good {
        border-bottom: 1px solid $gray;
        padding: 16px 0;

        &:first-child {
            @media(max-width: 576px) {
                padding-top: 0;
            }

            @media(min-width: 576px) {
                border-top: 1px solid $gray;
            }
        }
    }

    &__table {
        @media(min-width: 1201px) {
            grid-area: table;
        }
    }

    &__total {
        @media(min-width: 1201px) {
            grid-area: total;
        }
    }

    &__box {
        @media(min-width: 1201px) {
            grid-area: box;
            align-self: start;
        }
    }
}

.cart-good {
    $this: &; // .cart-good

    position: relative;
    display: flex;
    align-items: flex-start;

    @media(max-width: 359px) {
        flex-direction: column;
    }

    // .cart-good__cross

    &__cross {
        position: absolute;
        top: 17px;
        right: 0;
        padding-top: 0;
    }

    // .cart-good__img

    &__img {
        position: relative;
        flex-shrink: 0;
        margin-right: 50px;
        width: 110px;
        height: 110px;

        @media(max-width: 767px) {
            margin-right: 12px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    // .cart-good__content

    &__content {
        display: flex;
        align-items: flex-start;

        @media(max-width: 767px) {
            flex-direction: column;
            gap: 16px;
        }

        @media(min-width: 768px) {
            justify-content: space-between;
            flex-wrap: wrap;
            flex-grow: 1;
        }
    }

    // .cart-good__articul

    &__articul {
        @media(min-width: 768px) {
            margin-bottom: 8px;
            width: 100%;
        }
    }

    // .cart-good__title

    &__title {
        font-weight: 500;
        font-size: 16px;
        transition: .3s color;

        @media(max-width: 767px) {
            font-size: 14px;
            line-height: 130%;
        }

        @media(min-width: 768px) {
            width: 50%;
        }
    }

    // .cart-good__prices

    &__prices {
        display: flex;
        align-items: flex-end;

        @media(min-width: 768px) {
            width: 50%;
            justify-content: flex-end;
        }

        @media(max-width: 767px) {
            order: 4;
        }
    }

    // .cart-good__actions

    &__actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media(min-width: 768px) {
            width: 50%;
            margin-top: 16px;
        }
    }

    &__label {
        font-weight: 400;

        @media(min-width: 768px) {
            justify-self: flex-end;
            align-self: center;
        }

        @media(max-width: 767px) {
            order: 5;
        }
    }

    // .cart-good__counter

    &__counter {
        display: flex;
        align-items: center;
        margin-right: 25px;

        @media(max-width: 767px) {
            margin-right: 12px;
        }
    }

    &__favorite {
        padding: 0;
        border: none;
        text-transform: none;
        color: $gray-2;
        font-size: 13px;
        white-space: nowrap;
        margin: 10px 0;

        @media(max-width: 767px) {
            font-size: 13px;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 6px;

            path {
                transition: .3s stroke;
            }
        }

        @media (any-hover: hover) {
            &:hover {
                color: $red;

                svg path {
                    stroke: $red;
                }
            }
        }
    }

    &.favorite {
        #{$this} {
            &__favorite {
                color: $red;

                svg {
                    fill: $red;

                    path {
                        stroke: $red;
                    }
                }
            }
        }
    }

    //@media (any-hover: hover) {
    //    &:hover {
    //        #{$this} {
    //            &__title {
    //                color: $accent;
    //            }
    //        }
    //    }
    //}
}

.cart-good .cart-good__img:hover .cart-good__title {
  color: $accent;
}

.cart-good__title:hover {
  color: $accent;
}

.cart-info-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 510px;

    @media(min-width: 768px) {
        padding-top: 20px;
    }

    // .cart-box__title

    &__title {
        font-weight: 500;
        font-size: 24px;
        text-transform: uppercase;

        @media(max-width: 576px) {
            font-size: 18px;
            line-height: 130%;
        }

        &:not(:last-child) {
            margin-bottom: 20px;

            @media(max-width: 576px) {
                margin-bottom: 16px;
            }
        }

        &--green {
            color: #70A031;
        }
    }

    // .cart-box__text

    &__text {
        font-size: 16px;
        color: $gray-2;

        @media(max-width: 576px) {
            font-size: 14px;
            line-height: 130%;
        }

        &:not(:last-child) {
            margin-bottom: 30px;

            @media(max-width: 576px) {
                margin-bottom: 24px;
            }
        }
    }

    // .cart-box__btn

    &__btn {
        padding: 14px 47px;
    }
}
