// ===================== CHECKBOX ===================== //
.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:disabled ~ label:before {
        background: #cecece;
        border: 1px solid #cecece !important;
    }

    & ~ label {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        user-select: none;

        &.error {
            display: none !important;
        }

        &:not(.error) {
            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 0.25em;
                margin-right: 10px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 75% 60%;
            }
        }

        ~ * {
            a {
                color: $accent;
                text-decoration: underline;

                @media (any-hover: hover) {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.error {
        & ~ label {
            &:not(.error) {
                &::before {
                    border-color: $red;
                }
            }
        }
    }

    &:checked ~ label:not(.error)::before {
        border-color: $accent;
        background-color: $accent;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &--size {
        &_xl {
            & ~ label:not(.error)::before {
                width: 24px;
                height: 24px;
            }
        }
    }
}

// ===================== RADIO ===================== //
.radio {
    $this: &;

    cursor: pointer;

    &__box {
        width: 1px;
        height: 1px;
        overflow: hidden;
        position: absolute;
        clip: rect(0 0 0 0);
    }

    &__inner {
        position: relative;
        border: 1px solid #E9E8EE;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 24px;
        transition: .3s border-color;

        &:after {
            transition: .3s all;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
    }

    &__style {
        position: relative;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        flex-shrink: 0;
        border: 1px solid $gray-3;
        transition: .3s border-color;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $accent;
            opacity: 0;
        }
    }

    &__box {
        &:checked ~ #{$this} {

            &__inner {
                border-color: $accent;

                #{$this} {
                    &__style {
                        border-color: $accent;

                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
