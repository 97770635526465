.status {
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: flex;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.available {
        color: #70a031;

        &::before {
            background-image: url('../images/check.svg');
        }
    }

    &.not-available {
        color: $gray-3;

        &::before {
            background-image: url('../images/deny-status.svg');
        }
    }

    &.stock {
        &::before {
            background-image: url('../images/black-check.svg');
        }
    }
}
