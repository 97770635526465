#articles {
  padding-top: 120px;
}

.articles {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px;
  padding-top: 5px;

  @media (min-width: 991px) {
    overflow: visible;
  }
}

.articles-img__wrapper {
  position: relative;

  @media (min-width: 1100px) {
    height: 220px;
  }

  @media (min-width: 1650px) {
    height: 250px;
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: 6px;
  }
}

.article-img__btn {
  position: absolute;
  padding: 8px 12px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  background-color: $dark;
  border-radius: $brs;
  bottom: 16px;
  left: 16px;
}

.articles a {
  margin-bottom: 15px;
  transition: 0.35s;
}

@media (min-width: 991px) {
.articles .swiper-slide:hover {
  .articles-img__wrapper img {
    transform: scale(1.02);
    transition: 0.35s;
  }

}

.articles .swiper-slide .articles-img__wrapper img {
  transition: 0.35s;
}
}

@media (max-width: 576px) {
.articles {
  margin-top: 0;
}

.articles-img__wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.8%;
  position: relative;
}

.articles-img__wrapper img {
  height: 100%;
  position: absolute;
}
}
