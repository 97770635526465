:root {
    --container: 1360px;
    --header-height: 328px;
    --header-bottom-height: 0px;
    --tape-height: 76px;
}

html {
    height: 100%; // Footer at bottom
    font-size: 62.5%;
    //overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;

        @media (orientation: landscape) {
            padding-right: 17px;

            .header-main.fixed {
                transform: translateX(calc(-50% - 8px));
            }
        }
    }
}

body {
    display: flex; // Footer at bottom
    flex-direction: column; // Footer at bottom
    position: relative;
    min-height: 100vh;
    color: $dark;
    font-size: 14px;
    //line-height: 1.6rem;
    line-height: 140%;
    font-weight: 400;
    background: #fff;
    font-family: 'Roboto Flex', sans-serif;
    font-style: normal;

    font-stretch: 70%;
    font-variation-settings: 'GRAD'0, 'slnt'0, 'XTRA'468, 'XOPQ'96, 'YOPQ'79, 'YTLC'514, 'YTUC'712, 'YTAS'750, 'YTDE'-203, 'YTFI'738;

    @media (min-width: 576px) {
        overflow-x: hidden;
    }
}

.header {
    flex-shrink: 0; // Footer at bottom
}

.main {
    position: relative;
    flex: 1 0 auto; // Footer at bottom
}

.footer {
    z-index: 10;
    flex-shrink: 0; // Footer at bottom
    margin-top: auto;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;

    table & {
        max-width: none;
    }
}

button,
input,
textarea {
    font-family: inherit;
}

fieldset {
    border: none;
}

a {
    text-decoration: none;
    color: inherit;
    transition: .3s color;
}

.container {
    max-width: var(--container);
    margin: 0 auto;
}

.row-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 20px;

    @media(max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 8px;
    }
}

.row-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;

    @media(max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 8px;
    }
}

.page {
    padding-top: 50px;
    margin-bottom: 30px;

    @media(max-width: 767px) {
        padding-top: 36px;
    }

    @media (max-width: 576px) {
      overflow-x: hidden;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

.cart-cross {
    cursor: pointer;
    padding-top: 15px;

    svg {
        path {
            transition: .3s stroke;
        }
    }

    @media (any-hover: hover) {
        &:hover {
            svg path {
                stroke: $red;
            }
        }
    }
}

// Для растягивания грида на 2 колонки
.span-2 {
    grid-column: 1 / 3;
}

.small-text {
    font-size: 14px;
    letter-spacing: 0.02em;
    color: $gray-2;
}

body.open-mobile-menu {
    overflow: hidden;
    position: relative;
}

body.skroll-hidden {
    overflow: hidden;
}

.inactive {
    display: none !important;
}

@media (max-width: 1400px) {
    .container {
        padding: 0 16px;
    }
}

@media (max-width: 767px) {

    #navigator,
    #hits,
    #banners,
    #new-goods,
    #ortos-tab,
    #sale-goods,
    #brands,
    #special,
    #popular,
    #articles,
    #map-block,
    #footer {
        padding-top: 56px !important;
    }
}

@media (max-width: 576px) {
    .title {
        font-size: 26px;
    }
}

/* .iti__flag {
    background-image: url("../images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../images/flags@2x.png");
    }
} */

.fancybox-active {
    overflow: hidden;
}


.fancybox-button {
    width: 24px + 20px * 2;
    height: 24px + 20px * 2;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;

        path {
            fill: $gray-3;
        }
    }
}

.ymaps-2-1-79-balloon_layout_panel {
  border-radius: 6px;
}

.compensate-for-scrollbar {
  margin-right: 0 !important;
}

ymaps {
    border-radius: 6px;
}

.ymaps-2-1-79-map {
  overflow: hidden !important;
}

body.fancybox-active.compensate-for-scrollbar,
body.open-mobile-menu {
  .header-main.fixed {
    @media (min-width: 1150px) {
      margin-left: -8px;
    }
  }
}

/* .swiper img {
  opacity: 0;
} */

@media (max-width: 576px) {
  .catalog-content iframe {
    width: 100%;
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

.bx-section-desc ul {
    margin-left: 15px;
}

.__jivoMobileButton {
  bottom: 75px !important;
}
