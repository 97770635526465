#hits {
  padding-top: 120px;


}

.hits {
  position: relative;
}

.goods-slider {
  @media(min-width: 1201px) {
    padding: 40px 20px;
    //margin: 0 -20px;
  }
}

.goods-img {
  position: relative;

  @media (min-width: 1300px) {
    height: 275px;
    display: flex;
  }

  @media (min-width: 1490px) {
    height: 325px;
  }

  @media (max-width: 1025px) {
    height: 230px;
  }

  @media (max-width: 576px) {
    height: 212px;
  }

  @media (max-width: 380px) {
    height: 165px;
  }

  a {
    display: block;
  }



  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.goods-icon {
  position: absolute;
  top: 14px;
  width: 100%;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 576px) {
    top: 8px;
    @include padding-x(8px);
  }
}

.goods-icon__left-item {
  display: flex;
  flex-wrap: wrap;

  span {
    margin-right: 6px;
    margin-bottom: 4px;
  }
}

.goods-slide__content {
  padding: 16px;
}

.goods-slide__note {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #7f818c;
  margin-bottom: 10px;
  user-select: none;
}

.goods-slide__note span {
  text-transform: uppercase;
}

.goods-slide__name {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: $dark;
  margin-bottom: 24px;
  transition: .3s color;
  user-select: none;
  height: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;

  @media (max-width: 576px) {
    height: 50px;
  }

  @media (any-hover: hover) {
    &:hover {
      color: $accent;
    }
  }
}

.goods-slide__price {
  font-weight: 700;
  font-size: 18px;
}

.goods-btn {
  width: 100%;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: .3s all;
  user-select: none;

  span {
    @media(max-width: 992px) {
      display: none;
    }
  }
}

.goods-slide {
  border-radius: 10px;

  &.favorite {
    .favorite-btn {
      svg {
        fill: $red;

        path {
          stroke: $red;
        }
      }
    }
  }
}

.goods-slide:hover {
  box-shadow: 0px 7px 25px rgba(133, 133, 133, 0.12);
}

.goods-slide:hover .goods-btn {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.goods-icon__right-item {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.goods-icon__right-item:hover svg path {
  stroke: #F16363;
}

.arrow-goods {
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  border: 1px solid $gray-2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  transition: .3s border-color;
}

.arrow-goods {
  @media (any-hover: hover) {
    &:hover {
      border-color: $accent;
    }
  }
}

.arrow-goods {
  @media (any-hover: hover) {
    &:hover {
      svg path {
        fill: $accent;
      }
    }
  }
}

.arrow-goods__prev {
  left: -10%;
}

.arrow-goods__next {
  right: -10%;
}

@media (max-width: 1650px) {
  .arrow-goods__prev {
    left: -5%;
  }

  .arrow-goods__next {
    right: -5%;
  }
}

@media (max-width: 1500px) {
  .arrow-goods__prev {
    left: 0;
  }

  .arrow-goods__next {
    right: 0;
  }

  .goods-slider {
    width: 90%
  }
}

@media (max-width: 1300px) {
  .arrow-goods__prev {
    left: auto;
    right: 6%;
  }

  .arrow-goods {
    height: 36px;
    width: 36px;
  }

  .arrow-goods__next {
    right: 0;
  }

  .arrow-goods {
    top: 0;
  }

  .goods-slider {
    width: 100%
  }

  .goods-slide .goods-btn {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}

@media (max-width: 850px) {
  .goods-slide__note {
    height: 18px;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .goods-slide__name {
    font-size: 12px;
  }

  .goods-slide__price {
    font-size: 14px;
  }

  .goods-slide__price-old {
    font-size: 12px !important;
  }

  .goods-btn {
    font-size: 14px;
    padding: 12px;
  }

  .arrow-goods__prev {
    left: auto;
    right: 12%;
  }

  .goods-slide__note {
    font-size: 11px;
    height: 45px;
    overflow: hidden;
  }

  .goods-slide__price-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .goods-slide__price {
    order: 1;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }

  .goods-slide__price-old {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 450px) {
  #special .goods-icon__left-item:last-child {
    order: -1;
  }

  .goods-icon__left-item {
    margin-bottom: 0;
  }

  .goods-icon__left-item .sale {
    padding: 4px;
    font-size: 11px;
  }

  .goods-icon__right-item svg {
    width: 29px;
    margin-top: -3px;
  }

  .goods-icon__left-item svg {
    width: 77%;
    margin-top: -3px;
  }
}

@media (max-width: 380px) {
  .arrow-goods__prev {
    right: 13%;
  }
}

@media (max-width: 350px) {
  .arrow-goods__prev {
    left: auto;
    right: 14%;
  }
}

@media (max-width: 576px) {
  .goods-slide__content {
    padding: 10px 8px;
  }

  .goods-slide__note {
    margin-bottom: 6px;
  }

  .goods-slide__name {
    height: auto;
  }

  .prices-wrapper {
    margin-bottom: 0;
    height: 55px;
  }
}

@media (max-width: 576px) {
  .goods-slide__content {
    padding: 10px 8px;
  }

  .goods-slide__note {
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 14px;
  }

  .goods-slide__name {
    height: 48px;
  }

  .prices-wrapper {
    margin-bottom: 0;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .goods-img a {
    display: inline;
  }
}
