#footer {
    padding-top: 120px;
}

.footer-top {
    padding-top: 47px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-top__logo {
    img {
        width: 147px;
        height: 63px;
    }
}

.footer-phone__number {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    svg {
        transition: 0.35s;
    }
}

.footer-phone__number.active-phone {
    .footer-phone__number_text {
        color: $accent;
    }

    svg {
        transform: rotate(180deg);
        transition: 0.35s;
    }

    svg path {
        fill: $accent;
    }
}

.footer-phone__number_text {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $dark;
    padding-right: 6px;
}

.footer-top__mail {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $dark;
}

.footer-main {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
}

.footer-top__social {
    margin-right: 7%;
}

.footer-top__social a {
    margin-right: 10px;
}

.footer-main__list {
    display: flex;
    flex-direction: column;
}

.footer-main__list-item {
    font-weight: 400;
    font-size: 13px;
    color: $gray-2;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.footer-main__list-item:hover {
    color: $accent;
}

.footer-form__title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;

  @media (max-width: 575px) {
    .footer-form__title {
      margin-top: 15px;
    }
  }
}

.footer-form {
    display: flex;
    flex-direction: column;
}

.footer-form__label {
    display: flex;
    align-items: center;
    margin-top: 10px;

  div {
    width: 300px;
    max-width: 100%;
  }
}

.footer-form__label input {
    margin-right: 6px;
}

.footer-pay__title {
    font-weight: 500;
    font-size: 16px;
    margin-top: 45px;
    margin-bottom: 12px;
}

.footer-pay__img {
    max-width: 324px;
}

.footer-bottom {
    margin-top: 47px;
    padding-top: 30px;
    padding-bottom: 40px;
    border-top: 1px solid #e9e8ee;
    color: $gray-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-bottom__item:first-child {
    font-size: 13px;
}

.footer-bottom__item:nth-child(2) {
    font-size: 11px;
    max-width: 600px;
    margin-left: 60px;
}

.footer-bottom__item:nth-child(3) {
    margin-right: 10%;
}

.footer-main__list-title {
    margin-bottom: 20px;
    font-size: 14px;
}

.footer-main__list-title svg {
    display: none;
}

.footer-main-list__reward-title {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 10px;
}

.footer-main-list__reward-text {
    font-size: 13px;
    color: $gray-3;
    margin-top: 8px;
}

.footer-main__list:first-child {
    max-width: 140px;
    margin-right: 70px;
}

.footer-mobile__block {
    display: none;
    margin-bottom: 30px;
}

.footer-mobile__content-wrapper {
    width: 180px;
    margin-left: 12px;
}

.footer-mobile__content-wrapper .footer-main-list__reward-title {
    font-size: 12px;
}

.footer-mobile__content-wrapper .footer-main-list__reward-text {
    font-size: 13px;
}

.footer-form__label a {
    color: $accent;
    text-decoration: underline;
    margin-left: 3px;
}

.foore-main__list-links a {
    display: block;
}

.arrow-to-top {
    position: fixed;
    right: 50px;
    bottom: 157px;
    cursor: pointer;
    display: none;
    z-index: 5;

    @media (max-width: 575px) {
        display: none !important;
    }
}

.arrow-to-top {
    @media (any-hover: hover) {
        &:hover {
            svg rect {
                stroke: $accent;
            }

            svg path {
                fill: $accent;
            }
        }
    }
}

.footer-pay__mobile {
    display: none;
}

.footer-phone__popup {
    position: absolute;
    background: #fff;
    box-shadow: 0px 7px 7px rgba(133, 133, 133, 0.12);
    z-index: 2;
    width: 290px;
    top: 25px;
    display: none;
    margin-top: 3px;
}

.footer-phone__popup.active-phone {
    display: block;
}

@media (max-width: 1250px) {
    .footer-main {
        padding-left: 0;
    }
}

@media (max-width: 1070px) {
    .footer-main__list:first-child {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .footer-bottom {
        flex-wrap: wrap;
    }

    .footer-bottom__item {
        margin-bottom: 20px;
    }
}

@media (max-width: 870px) {
    .footer-main {
        flex-wrap: wrap;
    }

    .footer-top {
        flex-wrap: wrap;
    }

    .footer-top__logo {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .footer-bottom__item:nth-child(2) {
        margin-left: 0;
    }
}

@media (max-width: 576px) {
    #footer {
        padding-bottom: 60px;
        padding-top: 56px;
    }

    .header-bottom__navbar-item:last-child {
        display: none;
    }

    .footer-top {
        flex-direction: column;
        padding-top: 0;
    }

    .footer-bottom {
        border: none;
        margin-top: 0;
    }

    .footer-bottom__item:first-child br {
        display: none;
    }

    .footer-mobile__block {
        display: flex;
    }

    .footer-top__mail {
        margin: 20px 0;
    }

    .footer-form__wrapper {
        order: -1;
        margin-bottom: 13px;
        width: 100%;
    }

    .footer-form__wrapper.active-phone {
        visibility: hidden;
    }

    .footer-main {
        position: relative;
        padding-bottom: 115px;
    }

    .footer-pay {
        position: absolute;
        bottom: 0;
    }

    .footer-main__list:first-child {
        display: none;
    }

    .foore-main__list-links {
        height: 0;
        overflow: hidden;
    }

    .footer-top__social {
        width: 100%;
    }

    .footer-top__social a {
        margin-right: 8px;
    }

    .footer-top__social a img {
        width: 48px;
    }

    .footer-mob__tab {
        width: 100%;
        z-index: 1;
        transition: 0.35s;

        svg {
            transition: 0.35s;
        }
    }

    .footer-main__list-title {
        display: flex;
        align-items: center;
        margin-left: 9px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: $gray-2;
        margin-left: 0;
        padding: 16px 0;
        border-bottom: 1px solid #e9e8ee;
        margin-bottom: 0;
    }

    .footer-main__list-title svg {
        display: flex;
    }

    .footer-mob__tab.active {

        .footer-main__list-title {
            color: $accent;

            svg {
                transform: rotate(180deg);
                transition: 0.35s;
            }

            svg path {
                fill: $accent;
            }
        }

        .foore-main__list-links {
            height: auto;
            transition: 0.35s;
            padding-top: 10px;
        }
    }

    .arrow-to-top {
        bottom: 106px;
        right: 15px;
    }

    .footer-pay {
        display: none;
    }

    .footer-pay__mobile {
        display: block;
        width: 100%;
    }

    .footer-pay__img {
        max-width: 100%;
    }

/*     .footer-pay__img img {
        max-width: 100%;
    } */

    .footer-main {
        padding-bottom: 0;
    }

    .footer-pay__title {
        margin-top: 30px;
    }
}

@media (max-width: 330px) {
    .footer-top__social a img {
        width: 45px;
    }
}

.footer-form__id {
  order: -1;
}

.footer-form__label {
  flex-wrap: wrap;
  align-items: flex-start;
}
