.popup-size__wrapper {
  padding: 40px;
  padding-top: 20px;
  background: #fff;
  max-height: 90vh;
  aspect-ratio: 1 / 1;

  .popup-cross img {
    width: 24px;
    height: 24px;
  }
}

.popup-size {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-size.active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 576px) {
  .popup-size.active {
    padding: 12px;
  }

  .popup-size__wrapper {
    padding: 20px;
  }
}
