#catalog {
  display: none;
  position: absolute;
  width: 100%;
  top: calc(100% + var(--header-bottom-height));
  z-index: 20;
  transition: .3s top;
}

#catalog.active {
  display: block;
}

.catalog {
  background-color: #fff;
  box-shadow: 0px 7px 20px rgba(133, 133, 133, 0.12);
  display: flex;
}

.catalog-item {
  padding: 17px 0;
}

.catalog-first__menu {
  background-color: $gray;
  width: 412px;
}

.catalog-second__menu {
  width: 586px;
}

.catalog-first__list {
  list-style: none;
}

.catalog-first__list-item {
  //padding: 13px 30px;
  cursor: pointer;
}

.catalog-third__list {
  list-style: none;
}

.catalog-first__list-item a {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: $gray-2;
  padding: 13px 30px;
  display: block;
}

.catalog-first__list-item.active {
  background: #fff;
  color: $dark;
}

.catalog-first__list-item.active a {
  color: $dark;
}

.catalog-first__list-item:hover {
  background: #fff;
}

.catalog-second__list {
  position: absolute;
  display: none;
}

.catalog-second__list.active {
  position: static;
  display: block;
}

.catalog-second__list-item {
  padding: 13px 30px;
  cursor: pointer;
  width: inherit !important;
  position: relative;
}

.catalog-second__list-item a {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: $dark;
  display: flex;
  flex-direction: column;
  max-width: 83%;
}

.catalog-third__list-item {
  padding: 13px 30px;
  cursor: pointer;
}

ul.catalog-second__list .catalog-third__list-item {
  width: 340px;
}

.catalog-second__list-item_text {
  padding-top: 12px;
  text-align: center;
}

.catalog-third__list-item a {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: $gray-2;
}

.catalog-third__menu {
  width: 340px;
}

.catalog-third__list-item_text {
  padding-top: 12px;
  text-align: center;
}


.catalog-third__list-item_img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: $gray-2;
  margin-top: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.catalog-second__list-item_img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: $gray-2;
  margin-top: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.catalog-third__list {
  position: absolute;
  display: none;
}

.catalog-third__list.active {
  display: block;
  position: static;
}

.catalog-second__list-item.active {
  color: $accent;
  display: flex;
  align-items: center;
  width: max-content;
}

.catalog-second__list-item {
  display: flex;
  align-items: center;
  width: max-content;
}

.catalog-second__list-item.active a {
  color: $accent;
}

.catalog-second__list-item.active::after {
  content: '';
  display: flex;
  height: 10px;
  width: 30px;
  background: url(../images/arrow-gold.svg) no-repeat center center;
  margin-left: 20px;
}

.catalog-third__list-item:hover a {
  color: $accent;
}

@media (max-width: 1400px) {
  .catalog {
    width: 97%;
  }

  .catalog-first__menu {
    width: 300px;
  }

  .catalog-second__menu {
    width: 640px;
  }
}

@media (max-width: 1050px) {}

.header-main #catalog {
  height: 64vh;
  overflow: auto;
  box-shadow: 0px 7px 20px rgba(133, 133, 133, 0.12);
}

.header-main.fixed #catalog {
  height: 82vh;
}

#catalog li{
  list-style-type: none !important;
}

.header-main.fixed #catalog::-webkit-scrollbar {
  width: 0;
}
