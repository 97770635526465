.one-click__popup,.common__popup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    padding: 15vh 0;

//   .popup-cross img {
//     width: 24px;
//     height: 24px;
//   }
}


.one-click__popup.active, .common__popup.active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0s;
}

.one-click__popup-wrapper {
    padding: 40px;
    background: #fff;
    max-width: 520px;

    @media (max-width: 575px) {
      height: 100%;
      overflow: auto;
    }

  .error {
    color: #f95e5e;
  }
}

.popup-head {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: -20px;
}

.popup-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    color: $dark;
    margin-bottom: 20px;
    margin-top: -15px;
}

.popup-aftertitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $gray-2;
    margin-bottom: 34px;
}

.popup-form {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
}

.popup-form label {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $dark;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.popup-input {
    padding: 12px 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $dark;
    background: $gray;
    border-radius: $brs;
    margin-top: 8px;
    border: none;

}

.popup-input::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $dark;
}

//.popup-form label:nth-child(4) {
//    flex-direction: row !important;
//    font-weight: 400;
//    font-size: 14px;
//    line-height: 140%;
//    letter-spacing: 0.02em;
//    color: $dark;
//}

.checkbox-text a {
    color: $accent;
    text-decoration: underline;
}

.popup-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    padding: 14px;
    background: $dark;
}

.popup-btn:hover {
    background: #fce194;
    border: 1px solid #fce194;
    color: $dark;
}

.popup-cross {

    @media (max-width: 576px) {
        margin-right: 7px;
    }
}

@media (max-width: 576px) {
    .one-click__popup.active, .common__popup.active {
        padding: 12px;
    }

    .popup-title {
        font-size: 18px;
    }

    .popup-aftertitle {
        font-size: 14px;
    }

    .one-click__popup-wrapper {
        padding: 20px;
    }
}

.input-wrap.error,
.input-wrap.success {
  margin-bottom: 20px !important;
}
