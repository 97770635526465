.video {
  margin-top: 50px;
}

#video row-4 {
  gap: 20px 50px;
}

.video-item {
  cursor: pointer;
}

.video-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-top: 20px;
}

.video-wrapper iframe {
  display: none;
}

@media (max-width: 1150px) {
  .video-popup__wrapper {
    width: 85vw;
  }
}

@media (min-width: 991px) {
  .video-item:hover .video-title {
    color: #dba300;
  }
}

#video .pagination ul {
  margin-top: 50px;
}

@media (max-width: 576px) {
  .video-item img {
    width: 100%;
  }

  #video .pagination ul {
    margin-top: 30px;
  }

  .video-wrapper img {
    display: none;
  }

  .video-wrapper iframe {
    display: block;
    width: 100%;
    height: 192px;
  }
}

@media (min-width: 1400px) {
  .video-wrapper {


    img {
      height: 100%;
      border-radius: 6px;
    }
  }
}

@media (min-width: 991px) {
  .video-wrapper {


    img {
      height: 100%;
      border-radius: 6px;
    }
  }
}

@media (min-width: 576px) {
  .video-wrapper {


    img {
      height: 100%;
      border-radius: 6px;
    }
  }
}


@media (max-height: 450px) {
  .video-wrapper img {
    display: none;
  }

  .video-wrapper iframe {
    display: block;
  }
}

@media (min-width: 576px) {
  .video-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 67.6%;
    position: relative;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}
