#popular {
  padding-top: 55px;
}

.popular {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1050px) {
    justify-content: space-around;
  }
}

.popular-img__wrapper {
max-width: 325px;
width: 100%;
height: 325px;

img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
}

.popular-title {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: $dark;
  max-width: 380px;
}

.popular-item {
  margin-bottom: 15px;
  transition: 0.35s;
}

@media (min-width: 991px) {
  .popular-item:hover img {
      transform: scale(1.02);
      transition: 0.35s;
  }
}

@media (max-width: 576px) {
  .popular-item {
      width: 45%;
  }

.popular-img__wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.popular-img__wrapper img {
  position: absolute;
}
}

.popular-img__wrapper img {
  transition: 0.35s;
}
