@charset "UTF-8";
*,
::before,
::after {
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0; }

audio,
canvas,
progress,
video {
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

template {
  display: none; }

html {
  font-family: sans-serif;
  word-break: break-word;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default; }

body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

*:focus,
*:active,
*:hover {
  outline: none; }

hr {
  box-sizing: content-box;
  height: 0; }

ol,
ul {
  list-style: none; }

pre {
  white-space: pre-wrap;
  tab-size: 4; }

q {
  quotes: '«' '»'; }

button,
input {
  line-height: normal; }

button,
input,
select {
  margin: 0; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  vertical-align: top; }

button,
input,
select[multiple],
textarea {
  background-image: none; }

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none; }

input,
textarea {
  resize: none;
  user-select: text; }

button,
textarea,
select,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="number"],
input[type="range"] {
  appearance: none; }
  button::-ms-clear, button::-ms-reveal,
  textarea::-ms-clear,
  textarea::-ms-reveal,
  select::-ms-clear,
  select::-ms-reveal,
  input[type="text"]::-ms-clear,
  input[type="text"]::-ms-reveal,
  input[type="email"]::-ms-clear,
  input[type="email"]::-ms-reveal,
  input[type="password"]::-ms-clear,
  input[type="password"]::-ms-reveal,
  input[type="search"]::-ms-clear,
  input[type="search"]::-ms-reveal,
  input[type="submit"]::-ms-clear,
  input[type="submit"]::-ms-reveal,
  input[type="button"]::-ms-clear,
  input[type="button"]::-ms-reveal,
  input[type="reset"]::-ms-clear,
  input[type="reset"]::-ms-reveal,
  input[type="number"]::-ms-clear,
  input[type="number"]::-ms-reveal,
  input[type="range"]::-ms-clear,
  input[type="range"]::-ms-reveal {
    display: none; }

[placeholder]:focus::placeholder {
  color: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

iframe {
  border: none; }

.no-js-warning {
  position: fixed;
  z-index: 99999;
  width: 100%;
  padding: 15px 20px;
  color: #f00;
  font-size: 20px;
  background: rgba(255, 0, 0, 0.5); }

._progress {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0; }
  @media (max-width: 767px) {
    ._progress {
      padding: 50px 0; } }

@media (min-width: 768px) {
  ._progress__inner {
    columns: 2; } }

._progress__link {
  display: block;
  font-size: 1.5vmax;
  margin-bottom: 10px;
  font-weight: 700;
  color: #222;
  transition: opacity 0.3s; }
  @media (any-hover: hover) {
    ._progress__link:hover {
      opacity: 0.5; } }

:root {
  --container: 1360px;
  --header-height: 328px;
  --header-bottom-height: 0px;
  --tape-height: 76px; }

html {
  height: 100%;
  font-size: 62.5%; }
  html.no-scroll {
    overflow: hidden; }
    @media (orientation: landscape) {
      html.no-scroll {
        padding-right: 17px; }
        html.no-scroll .header-main.fixed {
          transform: translateX(calc(-50% - 8px)); } }

body {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  color: #2b2c2e;
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  background: #fff;
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-stretch: 70%;
  font-variation-settings: "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE"-203, "YTFI" 738; }
  @media (min-width: 576px) {
    body {
      overflow-x: hidden; } }

.header {
  flex-shrink: 0; }

.main {
  position: relative;
  flex: 1 0 auto; }

.footer {
  z-index: 10;
  flex-shrink: 0;
  margin-top: auto; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }
  table img {
    max-width: none; }

button,
input,
textarea {
  font-family: inherit; }

fieldset {
  border: none; }

a {
  text-decoration: none;
  color: inherit;
  transition: .3s color; }

.container {
  max-width: var(--container);
  margin: 0 auto; }

.row-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 20px; }
  @media (max-width: 992px) {
    .row-4 {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 8px; } }

.row-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 20px; }
  @media (max-width: 992px) {
    .row-3 {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 8px; } }

.page {
  padding-top: 50px;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .page {
      padding-top: 36px; } }
  @media (max-width: 576px) {
    .page {
      overflow-x: hidden; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }

.cart-cross {
  cursor: pointer;
  padding-top: 15px; }
  .cart-cross svg path {
    transition: .3s stroke; }
  @media (any-hover: hover) {
    .cart-cross:hover svg path {
      stroke: #F95E5E; } }

.span-2 {
  grid-column: 1 / 3; }

.small-text {
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #7F818C; }

body.open-mobile-menu {
  overflow: hidden;
  position: relative; }

body.skroll-hidden {
  overflow: hidden; }

.inactive {
  display: none !important; }

@media (max-width: 1400px) {
  .container {
    padding: 0 16px; } }

@media (max-width: 767px) {
  #navigator,
  #hits,
  #banners,
  #new-goods,
  #ortos-tab,
  #sale-goods,
  #brands,
  #special,
  #popular,
  #articles,
  #map-block,
  #footer {
    padding-top: 56px !important; } }

@media (max-width: 576px) {
  .title {
    font-size: 26px; } }

/* .iti__flag {
    background-image: url("../images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../images/flags@2x.png");
    }
} */
.fancybox-active {
  overflow: hidden; }

.fancybox-button {
  width: 64px;
  height: 64px; }
  .fancybox-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px; }
    .fancybox-button svg path {
      fill: #ABABAB; }

.ymaps-2-1-79-balloon_layout_panel {
  border-radius: 6px; }

.compensate-for-scrollbar {
  margin-right: 0 !important; }

ymaps {
  border-radius: 6px; }

.ymaps-2-1-79-map {
  overflow: hidden !important; }

@media (min-width: 1150px) {
  body.fancybox-active.compensate-for-scrollbar .header-main.fixed,
  body.open-mobile-menu .header-main.fixed {
    margin-left: -8px; } }

/* .swiper img {
  opacity: 0;
} */
@media (max-width: 576px) {
  .catalog-content iframe {
    width: 100%; } }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

.bx-section-desc ul {
  margin-left: 15px; }

.__jivoMobileButton {
  bottom: 75px !important; }

.title {
  font-weight: 500;
  font-size: 44px;
  text-transform: uppercase;
  color: #2b2c2e; }
  @media (max-width: 576px) {
    .title {
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 20px; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 140%; }

h1 {
  font-size: 44px;
  margin-bottom: 30px; }

h2 {
  font-size: 24px; }

button {
  outline: none;
  border: none; }

.button-order-date {
  background-color: #fff; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: #fff;
  border: 1px solid #2b2c2e;
  border-radius: 6px;
  padding: 14px;
  cursor: pointer;
  transition: .3s all;
  /* &:active,
    &:focus {
        background-color: $yellow;
        border-color: #FCE194;
    } */ }
  @media (any-hover: hover) {
    .btn:hover {
      background-color: #FCE194;
      border-color: #FCE194; } }
  .btn[disabled], .btn.disabled {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    color: #ABABAB; }
    @media (any-hover: hover) {
      .btn[disabled]:hover, .btn.disabled:hover {
        background-color: #F5F5F5;
        border-color: #F5F5F5;
        color: #ABABAB; } }
  .btn svg {
    flex-shrink: 0; }
  @media (any-hover: hover) {
    .btn:hover {
      background-color: #2b2c2e;
      color: #fff; } }
  .btn--transparent, .btn--border {
    background-color: #fff;
    color: #2b2c2e; }
  .btn--border {
    /* &:active,
        &:focus {
            border-color: #FCE194;
        } */ }
    @media (any-hover: hover) {
      .btn--border:hover {
        background-color: #FCE194;
        border-color: #FCE194;
        color: #2b2c2e; } }
  .btn--transparent-yellow {
    color: #2b2c2e;
    border-color: #FCE194;
    background-color: transparent;
    padding: 0;
    /*  &:active,
        &:focus {
            background-color: transparent;
        } */ }
    @media (any-hover: hover) {
      .btn--transparent-yellow:hover {
        background-color: #fce194;
        color: #deb054; } }
  .btn--transparent-yellow[disabled] {
    pointer-events: none;
    background-color: transparent;
    color: #AAAAAA;
    border-color: #AAAAAA; }
    @media (any-hover: hover) {
      .btn--transparent-yellow[disabled]:hover {
        background-color: transparent;
        color: #AAAAAA;
        border-color: #AAAAAA; } }
  .btn--transparent {
    background-color: transparent;
    padding: 0;
    /*  &:active,
        &:focus {
            background-color: transparent;
        } */ }
    @media (any-hover: hover) {
      .btn--transparent:hover {
        background-color: transparent;
        color: #2b2c2e; } }
  .btn--underlined {
    border: none;
    text-decoration-line: underline;
    color: #DBA300; }
  .btn--style_toggle {
    border: none;
    padding: 0;
    text-transform: none;
    font-size: 15px;
    /* &:active,
            &:focus {
                background-color: #fff;
                color: $dark;
            } */ }
    .btn--style_toggle[aria-expanded="true"] svg {
      transform: rotate(180deg); }
    .btn--style_toggle svg {
      flex-shrink: 0;
      margin-left: 6px;
      width: 20px;
      height: 20px;
      transition: .3s transform; }
      .btn--style_toggle svg path {
        transition: .3s fill; }
    @media (any-hover: hover) {
      .btn--style_toggle:hover {
        color: #DBA300; }
        .btn--style_toggle:hover svg path {
          fill: #DBA300; } }
  .btn--style_bg-black {
    background-color: #2b2c2e; }
    @media (any-hover: hover) {
      .btn--style_bg-black:hover {
        background-color: #FCE194;
        color: #2b2c2e;
        box-shadow: 0px 4px 40px rgba(225, 182, 57, 0); } }
  .btn--style_link {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #2b2c2e;
    color: #2b2c2e; }
    @media (any-hover: hover) {
      .btn--style_link:hover {
        background-color: transparent;
        border-bottom-width: 0;
        color: #2b2c2e; } }
  .btn__indent_m {
    padding: 15px 40px; }

.favorite-btn {
  background: transparent; }

.labels {
  position: absolute;
  top: 0;
  left: 0;
  display: flex; }

.label {
  border-radius: 6px;
  padding: 3px 3.5px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff; }
  .label--style_bg-red {
    background-color: #F95E5E; }
  .label--style_bg-green {
    background-color: #A2C871; }
  .label--style_bg-orange {
    background-color: #F3B265; }
  .label--style_bg-blue {
    background-color: #73A8F7; }
  .label--style_bg-pink {
    background-color: #F076C7; }
  .label--style_bg-gray {
    background-color: #E9E8EE;
    color: #7F818C; }
  .label--text-transform_lowercase {
    text-transform: lowercase;
    padding-right: 4.5px;
    padding-left: 4.5px; }

.form-field {
  display: flex;
  flex-direction: column; }

.input {
  background-color: #F5F5F5;
  border-radius: 6px;
  padding: 16px;
  border: none;
  height: 47px;
  font-size: 15px; }
  .input--with_border {
    background-color: #fff;
    border: 1px solid #E9E8EE; }
  .input:active, .input:focus {
    border: 1px solid #2b2c2e; }
  .input[readonly]:focus {
    border: none; }
  .input[readonly]:active {
    border: none; }
  .input.tel ~ .error {
    position: absolute;
    top: calc(100% + 2px);
    left: 0; }

.input.any {
  padding: 0 16px 0 145px; }

.input-label {
  font-size: 15px;
  margin-bottom: 8px; }
  @media (max-width: 576px) {
    .input-label {
      font-size: 14px;
      line-height: 130%; } }

.input-wrap {
  position: relative;
  display: block;
  height: 48px; }
  .input-wrap__reset {
    display: none;
    position: absolute;
    top: 13px;
    right: 16px;
    font-size: 15px;
    background-color: transparent;
    color: #7F818C;
    transition: .3s all; }
    @media (any-hover: hover) {
      .input-wrap__reset:hover {
        color: #F95E5E; } }
  .input-wrap__input {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px; }
    .input-wrap__input.input--with_border {
      border-color: #2b2c2e; }
    .input-wrap__input--ttu {
      text-transform: uppercase; }
    .input-wrap__input.active {
      border: 1px solid #2b2c2e; }
    .input-wrap__input::placeholder {
      color: #7F818C;
      text-transform: none; }
  .input-wrap__btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 52px;
    background-color: #2b2c2e;
    border-radius: 0px 6px 6px 0px;
    background-image: url(../images/submit-arrow.svg);
    background-position: center;
    background-size: 32px 8px;
    background-repeat: no-repeat;
    transition: .3s opacity; }
    .input-wrap__btn[disabled] {
      visibility: hidden;
      opacity: 0;
      pointer-events: none; }
    .input-wrap__btn::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0px 6px 6px 0px;
      background-image: url(../images/submit-arrow.svg);
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat; }
  .input-wrap__status {
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    font-size: 15px; }
    .input-wrap__status--success, .input-wrap__status--active {
      color: #70A031; }
    .input-wrap__status--error {
      color: #F95E5E; }
  .input-wrap.active .input-wrap__reset {
    display: inline-block; }
  .input-wrap.active .input-wrap__status--active {
    display: block; }
  .input-wrap.active .input-wrap__input[readonly] ~ .input-wrap__reset {
    display: none; }
  .input-wrap.success .input-wrap__input {
    border: 1px solid #70A031; }
  .input-wrap.success .input-wrap__btn::before {
    content: '';
    background-color: #F95E5E;
    background-image: url(../images/error-cross.svg); }
  .input-wrap.success .input-wrap__status--success {
    display: block; }
  .input-wrap.error .input-wrap__input {
    border: 1px solid #F95E5E; }
  .input-wrap.error .input-wrap__status--error {
    display: block; }

.loyalty-card .input-wrap__btn {
  display: none; }

@media (max-width: 576px) {
  .loyalty-card .input-wrap__input {
    font-size: 14px; } }

.loyalty-card .input-wrap.active {
  margin-bottom: 23px; }

.textarea {
  min-height: 142px;
  font-family: inherit;
  padding: 12px 16px;
  color: #2b2c2e; }
  .textarea::placeholder {
    color: #ABABAB; }

input.error {
  border: 1px solid #F95E5E; }
  input.error:active, input.error:focus {
    border-color: #F95E5E; }

.error {
  color: #F95E5E; }

label.error {
  display: block;
  font-size: 15px;
  margin-top: 2px; }
  @media (max-width: 576px) {
    label.error {
      font-size: 12px; } }

.personal-form__field .phone-select__title .phone-select__input {
  pointer-events: none; }

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-checkbox:disabled ~ label:before {
    background: #cecece;
    border: 1px solid #cecece !important; }
  .custom-checkbox ~ label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none; }
    .custom-checkbox ~ label.error {
      display: none !important; }
    .custom-checkbox ~ label:not(.error)::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.25em;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 75% 60%; }
    .custom-checkbox ~ label ~ * a {
      color: #DBA300;
      text-decoration: underline; }
      @media (any-hover: hover) {
        .custom-checkbox ~ label ~ * a:hover {
          text-decoration: none; } }
  .custom-checkbox.error ~ label:not(.error)::before {
    border-color: #F95E5E; }
  .custom-checkbox:checked ~ label:not(.error)::before {
    border-color: #DBA300;
    background-color: #DBA300;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
  .custom-checkbox--size_xl ~ label:not(.error)::before {
    width: 24px;
    height: 24px; }

.radio {
  cursor: pointer; }
  .radio__box {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0); }
  .radio__inner {
    position: relative;
    border: 1px solid #E9E8EE;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 24px;
    transition: .3s border-color; }
    .radio__inner:after {
      transition: .3s all; }
  .radio__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%; }
  .radio__style {
    position: relative;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    flex-shrink: 0;
    border: 1px solid #ABABAB;
    transition: .3s border-color; }
    .radio__style::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #DBA300;
      opacity: 0; }
  .radio__box:checked ~ .radio__inner {
    border-color: #DBA300; }
    .radio__box:checked ~ .radio__inner .radio__style {
      border-color: #DBA300; }
      .radio__box:checked ~ .radio__inner .radio__style:before {
        opacity: 1; }

.price {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  font-feature-settings: "pnum" on, "lnum" on; }

.price-old {
  font-weight: 400;
  font-size: 14px;
  color: #7F818C;
  text-decoration: line-through;
  margin-left: 6px; }

.prices {
  display: flex;
  align-items: flex-end; }

.articul {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ABABAB; }

.ui-selectmenu-button.ui-button {
  background: #fff;
  border: 1px solid #e9e8ee;
  border-radius: 6px;
  padding: 10px 12px;
  margin-left: 6px; }

.ui-widget {
  font-size: 15px;
  font-family: 'Roboto Flex', sans-serif; }

.ui-button .ui-icon {
  background: url("../images/arrow-gray.svg") no-repeat center center;
  transform: rotate(180deg); }

.button:focus .ui-icon {
  background: url("../images/arrow-gray.svg") no-repeat center center;
  transform: rotate(180deg); }

.ui-button:hover .ui-icon {
  background: url("../images/arrow-gray.svg") no-repeat center center;
  transform: rotate(180deg); }

.ui-button:focus .ui-icon {
  background: url("../images/arrow-gray.svg") no-repeat center center; }

.select--style_bg-gray + .ui-selectmenu-button.ui-button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  background: #F5F5F5;
  border: none;
  margin-left: 0; }

.select--style_bg-gray + .ui-button .ui-icon {
  background: url("../images/arrow-black.svg") no-repeat center center;
  transform: none; }

.select--style_bg-gray + .button:focus .ui-icon {
  background: url("../images/arrow-black.svg") no-repeat center center; }

.select--style_bg-gray + .ui-button:hover .ui-icon {
  background: url("../images/arrow-black.svg") no-repeat center center; }

.select--style_bg-gray + .ui-button:focus .ui-icon {
  background: url("../images/arrow-black.svg") no-repeat center center; }

.ui-selectmenu-button.ui-button[aria-expanded="true"] {
  border: 1px solid #2b2c2e; }
  .ui-selectmenu-button.ui-button[aria-expanded="true"] .ui-selectmenu-icon {
    transform: rotate(180deg); }

.ui-selectmenu-text {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2B2C2E; }

.ui-widget-content {
  color: #2b2c2e; }

.ui-widget.ui-widget-content {
  /* border: 1px solid $dark;
    border-top: none; */
  padding: 16px;
  border: none;
  background-color: #fff;
  box-shadow: 0px 7px 40px rgba(133, 133, 133, 0.12);
  border-radius: 6px;
  margin-top: 4px; }

.ui-state-active,
.ui-widget-content .ui-state-active {
  border: none;
  background: #F5F5F5;
  border-radius: 6px;
  font-size: 15px;
  color: #2b2c2e; }

.ui-menu .ui-menu-item-wrapper {
  padding: 9px 10px; }

/* .ui-selectmenu-button-open {
    border-bottom: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
} */
.phone-select {
  position: relative; }
  .phone-select__title {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    border-radius: 6px;
    height: 47px; }
    .phone-select__title img {
      width: 25px;
      height: 19px; }
  .phone-select__drop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    padding-right: 27px;
    background-color: transparent; }
    .phone-select__drop[disabled] {
      pointer-events: none; }
    .phone-select__drop span {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      white-space: nowrap;
      color: #2B2C2E; }
    .phone-select__drop::after {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      width: 17px;
      height: 17px;
      background-image: url(../images/arrow-black.svg);
      transition: .3s transform; }
  .phone-select__input {
    flex-grow: 1;
    padding: 0 16px 0 76px; }
    .phone-select__input::placeholder {
      color: #2b2c2e; }
    .phone-select__input:active, .phone-select__input:focus {
      border: none; }
  .phone-select__content {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 7px 40px rgba(133, 133, 133, 0.12);
    border-radius: 6px;
    z-index: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .phone-select__label {
    cursor: pointer;
    border-radius: 6px;
    padding: 10px;
    font-size: 15px;
    transition: .3s background-color; }
    .phone-select__label:nth-of-type(1) {
      padding-left: 47px;
      background-image: url(../images/blr.svg);
      background-size: 25px 19px;
      background-position: 10px 50%;
      background-repeat: no-repeat; }
    @media (any-hover: hover) {
      .phone-select__label:hover {
        background-color: #F5F5F5; } }
  .phone-select__option {
    display: none; }
  .phone-select.active .phone-select__title {
    border-color: #2b2c2e; }
  .phone-select.active .phone-select__drop:after {
    transform: rotate(180deg); }
  .phone-select.active .phone-select__content {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #2b2c2e;
  border: 1px solid #2b2c2e;
  border-radius: 6px;
  font-family: inherit;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  transition: .3s background-color, .3s border-color; }
  @media (any-hover: hover) {
    .tab:hover {
      background-color: #FCE194;
      border-color: #FCE194; } }
  .tab svg {
    flex-shrink: 0;
    margin-right: 6px;
    width: 14px;
    height: 14px; }
    .tab svg path {
      fill: #2b2c2e; }
    .tab svg circle {
      fill: #fff; }
  .tab.active {
    background-color: #2b2c2e;
    color: #fff; }
    @media (any-hover: hover) {
      .tab.active:hover {
        border-color: #2b2c2e; } }
    .tab.active svg path {
      fill: #fff; }
    .tab.active svg circle {
      fill: #2b2c2e; }
  .tab--with_brs-left {
    border-radius: 6px 0px 0px 6px; }
  .tab--with_brs-right {
    border-radius: 0px 6px 6px 0px; }
  .tab--with_brs-left, .tab--with_brs-right {
    font-size: 14px;
    padding: 12px 24px; }

.tabs-content {
  display: none; }
  .tabs-content.active {
    display: block; }

.page-tabs {
  padding-bottom: 50px; }
  @media (min-width: 768px) {
    .page-tabs {
      display: flex; } }
  @media (max-width: 767px) {
    .page-tabs {
      display: grid;
      gap: 8px; } }
  .page-tabs__item {
    padding: 15px; }
    @media (min-width: 768px) {
      .page-tabs__item {
        min-width: 210px;
        margin-right: 20px; } }
  @media (max-width: 576px) {
    .page-tabs {
      padding-bottom: 30px; } }

.modal {
  padding: 40px;
  width: 1130px;
  background: #fff; }
  @media (max-width: 1200px) {
    .modal {
      width: calc(100% - 16px * 2); } }
  .modal--size_xs {
    width: 520px; }
    @media (max-width: 1200px) {
      .modal--size_xs {
        width: 520px; } }
  @media (max-width: 767px) {
    .modal {
      padding: 30px 20px; } }
  .modal__back {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    transform: rotate(90deg); }
  .modal__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      .modal__title {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 16px; } }
  .modal__subtitle {
    letter-spacing: 0.02em;
    color: #7F818C;
    margin-bottom: 28px;
    margin-top: 20px; }
    @media (max-width: 576px) {
      .modal__subtitle {
        margin-top: 23.5px; } }
  .modal__form {
    display: grid;
    gap: 24px; }
  .modal__field {
    margin-bottom: 6px; }
  .modal__agree {
    margin-bottom: -8px;
    flex-direction: row !important; }
  .modal__code {
    color: #7F818C;
    margin-bottom: 14px; }
    .modal__code > span:first-child {
      text-decoration: underline;
      cursor: pointer; }
  .modal__code-timer {
    color: #DBA300; }

.shops-table {
  font-size: 15px; }
  @media (min-width: 576px) and (max-width: 767px) {
    .shops-table__body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 30px; } }
  .shops-table__row {
    display: grid;
    gap: 16px;
    padding: 26px 0; }
    @media (max-width: 767px) {
      .shops-table__row {
        padding: 20px 0; } }
    @media (min-width: 768px) {
      .shops-table__row {
        grid-template-columns: 0.6fr 0.6fr 0.4fr 0.4fr;
        align-items: flex-start; } }
    .shops-table__row:last-child {
      padding-bottom: 0; }
    .shops-table__row:not(:last-child) {
      border-bottom: 1px solid #E9E8EE; }
  .shops-table__head {
    display: none; }
    @media (min-width: 768px) {
      .shops-table__head {
        display: grid;
        color: #7F818C;
        padding: 0 0 16px;
        border-bottom: 1px solid #E9E8EE; } }
    .shops-table__head .shops-table__col--availability {
      text-align: right;
      padding-right: 38px; }
  @media (max-width: 767px) {
    .shops-table__col:first-child {
      font-weight: 600;
      font-size: 14px; } }
  @media (any-hover: hover) {
    .shops-table__col a:hover {
      color: #DBA300; } }
  .shops-table__col--address, .shops-table__col--timetable {
    max-width: 280px; }
  @media (min-width: 768px) {
    .shops-table__col--phones {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; } }
  @media (min-width: 768px) {
    .shops-table__col--availability {
      display: flex;
      flex-direction: column;
      align-items: flex-end; } }
  @media (max-width: 767px) {
    .shops-table__col--availability {
      margin-top: -16px; } }
  .shops-table__phones {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767px) {
      .shops-table__phones {
        margin-bottom: 12px; } }
  .shops-table__btn {
    padding: 14px 43px; }

.shops-popup .shops-table__head {
  margin-right: 24px; }

.shops-popup .shops-table__body {
  padding-right: 24px;
  height: calc(802px - 40px * 2 - 34px - 30px * 2 - 42px - 21px - 16px);
  overflow: auto;
  scrollbar-width: thin; }
  .shops-popup .shops-table__body::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
    border-radius: 10px; }
  .shops-popup .shops-table__body::-webkit-scrollbar-thumb {
    background-color: #E9E8EE;
    border-radius: 10px; }

@media (min-width: 768px) {
  .shops-popup .shops-table__row {
    grid-template-columns: 0.25fr 0.25fr 0.5fr; } }

@media (min-width: 1201px) {
  .shops-popup .shops-table__row {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 767px) {
  .shops-popup .shops-table__row:first-child {
    padding-top: 0; } }

.shops-page__map {
  height: 500px;
  margin-bottom: 60px; }
  @media (max-width: 576px) {
    .shops-page__map {
      height: 360px;
      margin-bottom: 20px; } }

.shops-page__tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 45px; }
  @media (max-width: 576px) {
    .shops-page__tabs {
      margin: 0 -4px 16px; } }

.shops-page__tab {
  min-width: 132px;
  margin: 5px;
  padding: 15px; }
  @media (max-width: 576px) {
    .shops-page__tab {
      width: calc(50% - 8px);
      margin: 4px; } }

.shops-page__table {
  width: 1130px;
  max-width: 100%; }
  @media (min-width: 768px) {
    .shops-page__table .shops-table__row {
      grid-template-columns: 0.4fr 0.4fr 0.2fr; } }

.status {
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center; }
  .status::before {
    content: '';
    display: flex;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-position: center;
    background-repeat: no-repeat; }
  .status.available {
    color: #70a031; }
    .status.available::before {
      background-image: url("../images/check.svg"); }
  .status.not-available {
    color: #ABABAB; }
    .status.not-available::before {
      background-image: url("../images/deny-status.svg"); }
  .status.stock::before {
    background-image: url("../images/black-check.svg"); }

/* *[class$="balloon__close"] {
    @media(min-width: 768px) {
        display: none !important;
    }
} */
*[class$="balloon__tail"] {
  display: none !important; }

*[class$="balloon__content"] {
  font-family: 'Roboto Flex', sans-serif !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 140% !important;
  font-variation-settings: "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE"-203, "YTFI" 738;
  padding: 0 !important;
  margin-right: 0 !important; }
  @media (max-width: 576px) {
    *[class$="balloon__content"] {
      font-size: 12px !important; } }
  *[class$="balloon__content"] > * {
    width: auto !important;
    height: auto !important;
    overflow: visible !important; }

.map-balloon {
  width: max-content;
  padding: 20px;
  box-shadow: 0px 7px 40px rgba(133, 133, 133, 0.12);
  border-radius: 6px;
  display: grid;
  row-gap: 16px; }
  @media (max-width: 576px) {
    .map-balloon {
      width: 100%;
      padding: 16px;
      row-gap: 8px; } }
  .map-balloon__logo {
    display: block;
    width: 117px;
    height: 50px; }
    @media (min-width: 768px) {
      .map-balloon__logo {
        margin-right: auto;
        margin-left: auto; } }
  .map-balloon__list {
    display: grid;
    row-gap: 8px; }
  .map-balloon__list-item {
    display: flex; }
    .map-balloon__list-item dt {
      font-weight: 600; }
    .map-balloon__list-item dd {
      margin-left: 6px; }
      @media (max-width: 576px) {
        .map-balloon__list-item dd {
          margin-left: 4px; } }

.alerts {
  display: grid;
  gap: 20px; }

.alert {
  position: relative;
  padding: 26px 26px 26px calc(26px + 24px + 10px);
  border-radius: 14px;
  font-size: 16px;
  letter-spacing: 0.02em; }
  .alert-message {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 554px;
    transform: translate(-50%, 200%);
    z-index: 999992;
    transition: transform 0.5s, opacity 0.5s;
    opacity: 0; }
    .alert-message--active {
      transform: translate(-50%, -50%);
      opacity: 1; }
  .alert::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px; }
  .alert.success {
    background-color: #E7FCCD; }
    .alert.success:before {
      background-image: url(../images/alert-check.svg); }
  .alert.warning {
    background-color: #FFF0C4; }
    .alert.warning:before {
      background-image: url(../images/alert-warning.svg); }
  .alert.error {
    background-color: #FFDBDB;
    color: #2b2c2e; }
    .alert.error:before {
      background-image: url(../images/alert-error.svg); }

@media (max-width: 576px) {
  .alert-message {
    width: 328px; } }

.article-detail-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 970px;
  margin-top: 20px; }

.article-detail-info__social-links {
  display: flex;
  align-items: center; }
  .article-detail-info__social-links a {
    margin-left: 16px; }

@media (min-width: 991px) {
  .article-page__item-img_wrapper {
    height: 570px; }
  #article-page-detail
.article-page__head
.article-page__head-item:first-child
img {
    border-radius: 6px; } }

#article-page-detail ul {
  padding-left: 20px;
  list-style: disc; }

#article-page-detail ol {
  padding-left: 20px; }

#article-page-detail .article-page__head-item:last-child {
  width: initial; }

#article-page-detail .article-page__head-item:last-child a:first-child {
  display: flex; }

#article-page-detail .article-page-content img {
  margin-bottom: 50px; }

#article-page-detail .article-page-content__text_black {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2b2c2e !important; }

#article-page-detail .article-detail-info__item .article-tag {
  margin-bottom: 0;
  color: #fff;
  background: #2b2c2e; }

#article-page-detail .article-page__head-item_title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  border-bottom: 1px solid #e9e8ee; }

#article-page-detail .article-page__item-title {
  margin-top: 0; }

#article-page-detail .article-page__head-block-item {
  margin-bottom: 0;
  padding: 30px 0;
  border-bottom: 1px solid #e9e8ee; }

#article-page-detail .article-page__head {
  margin-top: 24px;
  margin-bottom: 50px;
  border-bottom: none;
  padding-bottom: 0; }
  #article-page-detail .article-page__head .article-page__head-item:first-child {
    width: 100%;
    max-width: 970px; }
    #article-page-detail .article-page__head .article-page__head-item:first-child img {
      width: 100%;
      height: 100%; }

#article-page-detail .article-page-content {
  width: 940px; }

#article-page-detail .article-detail-info .article-page__item-date {
  margin-top: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.02em;
  color: #7f818c;
  text-transform: initial; }

#article-page-detail .article-page-content__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7f818c;
  margin-top: 30px;
  margin-bottom: 50px; }
  #article-page-detail .article-page-content__text ul {
    list-style: inside;
    margin-left: 7px; }
    #article-page-detail .article-page-content__text ul li {
      margin-bottom: 10px; }

#article-page-detail .article-page-content__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e; }

#article-page-detail .article-page-content__title_small {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2b2c2e;
  margin-top: 50px; }

#article-page-detail .article-page__head-item_mobile {
  display: none;
  padding: 0 16px; }

@media (max-width: 1400px) {
  #article-page-detail .article-page__head-item:first-child {
    display: block; }
  #article-page-detail .article-page-content {
    padding: 0 16px; }
  #article-page-detail .article-page-content img {
    padding-left: 16px; }
  #article-page-detail .article-page__head {
    flex-wrap: nowrap;
    padding: 0 16px; }
  #article-page-detail .container {
    padding: 0; }
  #article-page-detail .title {
    padding: 0 16px; }
  #article-page-detail .article-detail-info {
    padding: 0 16px; }
  #article-page-detail .article-page__head-item:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 20px; }
  #article-page-detail .article-page__head-block-item {
    padding: 10px 0; }
  #article-page-detail .article-page__head-item_title {
    padding-bottom: 10px; }
  #article-page-detail .article-page__head-item:last-child {
    gap: 10px 20px; }
  #article-page-detail .article-page-content__text {
    padding: 0 16px; }
  #article-page-detail .article-page-content__title {
    padding: 0 16px; }
  #article-page-detail .article-page-content__title_small {
    padding: 0 16px; } }

@media (max-width: 970px) {
  #article-page-detail .article-page__head-item:last-child {
    display: none; }
  #article-page-detail .article-detail-info {
    width: 100%; }
  #article-page-detail .article-page-content {
    width: 100%; }
  #article-page-detail .article-page__head-item_mobile {
    display: block !important;
    margin-left: 0 !important;
    margin-top: 34px; } }

@media (max-width: 576px) {
  #article-page-detail .article-page__head {
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0; }
  #article-page-detail .article-page-content img {
    padding-left: 0; }
  #article-page-detail .article-page-content__text {
    margin-top: 20px;
    margin-bottom: 40px; }
  #article-page-detail .article-page-content__title {
    font-size: 16px; }
  #article-page-detail .article-page-content__title_small {
    font-size: 16px;
    margin-top: 30px;
    text-transform: initial; }
  #article-page-detail .article-page__head {
    padding-bottom: 40px;
    margin-bottom: 0; }
  #article-page-detail .article-page__head-item_title {
    font-size: 24px;
    padding-bottom: 30px;
    margin-top: 34px; }
  #article-page-detail .article-page__item-date {
    margin-top: 10px; }
  #article-page-detail .article-page__head-block-item {
    padding: 20px 0; }
  #article-page-detail .article-detail-info {
    flex-direction: column;
    align-items: flex-start; }
  #article-page-detail .article-detail-info__item .article-tag {
    margin-bottom: 36px; }
  .article-detail-info__social-links a {
    margin-left: 10px; }
  .article-page__head-item_mobile {
    margin-top: 0 !important; } }

.dop-page-title {
  margin-top: 50px; }
  .dop-page-title .text-page p,
  .dop-page-title .text-page ul li {
    text-align: justify;
    padding-top: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%; }
  .dop-page-title .text-page ul {
    padding-left: 20px;
    list-style: disc; }

#article-page .row-3 {
  gap: 40px 100px;
  margin-top: 50px; }

.article-page {
  margin-top: 50px; }

.article-page__item-title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2; }

.article-page__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #7f818c;
  margin-top: 16px;
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 8; }

.article-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px; }

.article-tag {
  padding: 6px 12px;
  border: 1px solid #2B2C2E;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer; }

.article-tag.active {
  background: #2B2C2E;
  color: #fff; }

.article-page__item-img {
  position: relative; }
  .article-page__item-img .article-tag {
    position: absolute;
    bottom: 0;
    left: 16px;
    background: #2B2C2E;
    color: #fff; }

.article-page__item-img {
  height: 220px; }

.article-page__item-img img {
  height: 100%;
  border-radius: 6px;
  width: 100%; }

@media (max-width: 576px) {
  .article-page__item-img {
    height: auto; } }

@media (min-width: 576px) {
  .article-tag:hover {
    background: #2B2C2E;
    color: #fff; } }

@media (max-width: 576px) {
  #article-page .row-3 {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
    gap: 30px 100px; }
  .dop-page-title {
    margin-top: 13px;
    margin-bottom: 30px; }
  .article-page__item-title, .news-page__item-text {
    height: auto; } }

@media (min-width: 991px) {
  .article-page__item:hover {
    cursor: pointer; }
    .article-page__item:hover .article-page__item-title {
      color: #DBA300; } }

.article-page__item-date {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ababab;
  margin-top: 16px; }

.article-page__head-item:last-child {
  width: 555px; }

.article-page__head-block-item {
  width: 325px;
  margin-bottom: 20px; }

.article-page__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.article-page__head-block {
  display: flex;
  justify-content: space-between; }

.article-page__head {
  margin-top: 50px; }

#article-page .pagination ul {
  justify-content: center;
  margin-top: 30px; }

@media (min-width: 991px) {
  .article-page__head-block:hover .article-page__item-title {
    color: #DBA300; } }

.article-page__head {
  padding-bottom: 30px;
  border-bottom: 1px solid #E9E8EE; }

.article-page__head-item:last-child a:first-child {
  display: none; }

@media (max-width: 1400px) {
  .article-page__head-item:first-child {
    display: none; }
  .article-page__head-item:last-child a:first-child {
    display: block; }
  .article-page__head-item:last-child {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 20px; }
  .article-page__head-block {
    display: block; }
  .article-page__head-block-item {
    width: 100%; }
  #article-page .row-4 {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 930px) {
  #article-page .row-4 {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 8px; }
  .article-page__head-item:last-child {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 576px) {
  #article-page .row-4 {
    display: flex;
    flex-direction: column; }
  .article-page__head-item:last-child {
    display: flex;
    flex-direction: column; }
  .article-page__head-block-item img {
    width: 100%; }
  .article-page__head {
    margin-top: 15px; }
  .article-page__head-block-item {
    margin-bottom: 0; }
  .article-page__head {
    padding-bottom: 40px; }
  .article-page {
    margin-top: 30px; }
  .article-page__item-img img {
    width: 100%; }
  .article-tags {
    margin-top: 15px; }
  .article-tag {
    margin-bottom: 8px;
    margin-right: 8px; } }

#articles {
  padding-top: 120px; }

.articles {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px;
  padding-top: 5px; }
  @media (min-width: 991px) {
    .articles {
      overflow: visible; } }

.articles-img__wrapper {
  position: relative; }
  @media (min-width: 1100px) {
    .articles-img__wrapper {
      height: 220px; } }
  @media (min-width: 1650px) {
    .articles-img__wrapper {
      height: 250px; } }
  .articles-img__wrapper img {
    width: 100%;
    height: inherit;
    border-radius: 6px; }

.article-img__btn {
  position: absolute;
  padding: 8px 12px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  background-color: #2b2c2e;
  border-radius: 6px;
  bottom: 16px;
  left: 16px; }

.articles a {
  margin-bottom: 15px;
  transition: 0.35s; }

@media (min-width: 991px) {
  .articles .swiper-slide:hover .articles-img__wrapper img {
    transform: scale(1.02);
    transition: 0.35s; }
  .articles .swiper-slide .articles-img__wrapper img {
    transition: 0.35s; } }

@media (max-width: 576px) {
  .articles {
    margin-top: 0; }
  .articles-img__wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 56.8%;
    position: relative; }
  .articles-img__wrapper img {
    height: 100%;
    position: absolute; } }

#banners {
  padding-top: 55px; }

.banners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px; }
  @media (min-width: 991px) {
    .banners {
      overflow: visible; } }

.banners-img {
  margin-top: 15px;
  border-radius: 6px;
  transition: 0.35s; }
  @media (max-width: 576px) {
    .banners-img {
      height: 175px; } }
  .banners-img img {
    height: 100%;
    border-radius: 6px;
    width: 100%; }
    @media (min-width: 1500px) {
      .banners-img img {
        height: 360px;
        width: 100%; } }

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #DBA300; }

.swiper-pagination-bullet {
  background: #d8bd6e; }

@media (min-width: 991px) {
  .banners-img:hover {
    transform: scale(1.03);
    transition: 0.35s; } }

.dop-page-title {
  margin-top: 50px; }

#brand-page .row-3 {
  gap: 40px 100px;
  margin-top: 50px; }

.brand-page__item-title {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase; }

.brand-page__item-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7f818c;
  margin-top: 10px; }

.brand-page__item-img {
  height: 140px; }

.brand-page__item-img img {
  height: 100%; }

@media (max-width: 576px) {
  #brand-page .row-3 {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
    gap: 30px 100px; }
  .dop-page-title {
    margin-top: 15px; } }

#brands {
  padding-top: 55px; }

.brands {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.brands-item {
  width: 256px;
  margin-bottom: 20px; }
  .brands-item img {
    height: 100px;
    width: 100%; }

.brands-item:not(last-child):hover {
  filter: drop-shadow(0px 7px 40px rgba(133, 133, 133, 0.12));
  border-radius: 6px; }

.brands-item:last-child {
  display: flex;
  align-items: center;
  justify-content: center; }

.brand-btn {
  font-size: 14px;
  text-transform: uppercase;
  padding-right: 20px;
  padding-left: 20px; }

.brand-btn svg {
  margin-left: 8px; }

@media (max-width: 576px) {
  .brands {
    justify-content: center; }
  .brands-item {
    width: 150px; }
    .brands-item img {
      height: auto;
      width: 100%; }
  .brand-btn {
    font-size: 14px;
    padding: 14px; }
    .brand-btn svg {
      width: 16px; } }

#breadcrumbs {
  padding-top: 20px; }

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.breadcrumbs-item {
  display: flex;
  align-items: center; }

.breadcrumbs-item::after {
  content: '';
  background: #ABABAB;
  height: 12px;
  width: 1px;
  display: inline-block;
  margin: 0 13px; }

.breadcrumbs-item:last-child:after {
  display: none; }

.breadcrumbs-item a {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #ABABAB; }

.conter-input__good-number {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #2b2c2e; }

@media (max-width: 576px) {
  #breadcrumbs {
    padding-bottom: 20px; }
  .breadcrumbs-item a {
    font-size: 12px; } }

.cart-box {
  background-color: #FFFFFF;
  box-shadow: 0px 7px 40px rgba(133, 133, 133, 0.12);
  display: flex;
  align-items: center;
  padding: 20px 16px; }
  .cart-box__icon {
    flex-shrink: 0;
    width: 61px;
    height: 44px;
    margin-right: 16px;
    fill: #DBA300; }
  .cart-box__text {
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #2b2c2e; }
    @media (max-width: 576px) {
      .cart-box__text {
        max-width: 196px; } }
    .cart-box__text span:last-child {
      color: #DBA300; }
  .cart-box.free .cart-box__icon {
    fill: #70A031; }

.cart__body {
  display: grid; }
  @media (min-width: 1201px) {
    .cart__body {
      column-gap: 49px;
      grid-template-columns: 1fr 296px;
      grid-template-rows: max-content 10px max-content;
      grid-template-areas: 'table .' 'total .' 'total box'; } }
  @media (max-width: 1200px) {
    .cart__body {
      gap: 16px; } }

.cart__good {
  border-bottom: 1px solid #F5F5F5;
  padding: 16px 0; }
  @media (max-width: 576px) {
    .cart__good:first-child {
      padding-top: 0; } }
  @media (min-width: 576px) {
    .cart__good:first-child {
      border-top: 1px solid #F5F5F5; } }

@media (min-width: 1201px) {
  .cart__table {
    grid-area: table; } }

@media (min-width: 1201px) {
  .cart__total {
    grid-area: total; } }

@media (min-width: 1201px) {
  .cart__box {
    grid-area: box;
    align-self: start; } }

.cart-good {
  position: relative;
  display: flex;
  align-items: flex-start; }
  @media (max-width: 359px) {
    .cart-good {
      flex-direction: column; } }
  .cart-good__cross {
    position: absolute;
    top: 17px;
    right: 0;
    padding-top: 0; }
  .cart-good__img {
    position: relative;
    flex-shrink: 0;
    margin-right: 50px;
    width: 110px;
    height: 110px; }
    @media (max-width: 767px) {
      .cart-good__img {
        margin-right: 12px; } }
    .cart-good__img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .cart-good__content {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767px) {
      .cart-good__content {
        flex-direction: column;
        gap: 16px; } }
    @media (min-width: 768px) {
      .cart-good__content {
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1; } }
  @media (min-width: 768px) {
    .cart-good__articul {
      margin-bottom: 8px;
      width: 100%; } }
  .cart-good__title {
    font-weight: 500;
    font-size: 16px;
    transition: .3s color; }
    @media (max-width: 767px) {
      .cart-good__title {
        font-size: 14px;
        line-height: 130%; } }
    @media (min-width: 768px) {
      .cart-good__title {
        width: 50%; } }
  .cart-good__prices {
    display: flex;
    align-items: flex-end; }
    @media (min-width: 768px) {
      .cart-good__prices {
        width: 50%;
        justify-content: flex-end; } }
    @media (max-width: 767px) {
      .cart-good__prices {
        order: 4; } }
  .cart-good__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .cart-good__actions {
        width: 50%;
        margin-top: 16px; } }
  .cart-good__label {
    font-weight: 400; }
    @media (min-width: 768px) {
      .cart-good__label {
        justify-self: flex-end;
        align-self: center; } }
    @media (max-width: 767px) {
      .cart-good__label {
        order: 5; } }
  .cart-good__counter {
    display: flex;
    align-items: center;
    margin-right: 25px; }
    @media (max-width: 767px) {
      .cart-good__counter {
        margin-right: 12px; } }
  .cart-good__favorite {
    padding: 0;
    border: none;
    text-transform: none;
    color: #7F818C;
    font-size: 13px;
    white-space: nowrap;
    margin: 10px 0; }
    @media (max-width: 767px) {
      .cart-good__favorite {
        font-size: 13px; } }
    .cart-good__favorite svg {
      width: 18px;
      height: 18px;
      margin-right: 6px; }
      .cart-good__favorite svg path {
        transition: .3s stroke; }
    @media (any-hover: hover) {
      .cart-good__favorite:hover {
        color: #F95E5E; }
        .cart-good__favorite:hover svg path {
          stroke: #F95E5E; } }
  .cart-good.favorite .cart-good__favorite {
    color: #F95E5E; }
    .cart-good.favorite .cart-good__favorite svg {
      fill: #F95E5E; }
      .cart-good.favorite .cart-good__favorite svg path {
        stroke: #F95E5E; }

.cart-good .cart-good__img:hover .cart-good__title {
  color: #DBA300; }

.cart-good__title:hover {
  color: #DBA300; }

.cart-info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 510px; }
  @media (min-width: 768px) {
    .cart-info-box {
      padding-top: 20px; } }
  .cart-info-box__title {
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase; }
    @media (max-width: 576px) {
      .cart-info-box__title {
        font-size: 18px;
        line-height: 130%; } }
    .cart-info-box__title:not(:last-child) {
      margin-bottom: 20px; }
      @media (max-width: 576px) {
        .cart-info-box__title:not(:last-child) {
          margin-bottom: 16px; } }
    .cart-info-box__title--green {
      color: #70A031; }
  .cart-info-box__text {
    font-size: 16px;
    color: #7F818C; }
    @media (max-width: 576px) {
      .cart-info-box__text {
        font-size: 14px;
        line-height: 130%; } }
    .cart-info-box__text:not(:last-child) {
      margin-bottom: 30px; }
      @media (max-width: 576px) {
        .cart-info-box__text:not(:last-child) {
          margin-bottom: 24px; } }
  .cart-info-box__btn {
    padding: 14px 47px; }

.cart-popup {
  position: absolute;
  background: #fff;
  box-shadow: 0px 7px 5px rgba(133, 133, 133, 0.12);
  border-radius: 6px;
  padding: 20px;
  z-index: 2;
  top: 125%;
  right: 0;
  width: 520px;
  display: none; }
  .cart-popup .cart-popup__good_wrapper {
    max-height: 24vw;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #cecece #e8e8e8; }
  .cart-popup .cart-popup__good_wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #cecece; }
  .cart-popup .cart-popup__good_wrapper::-webkit-scrollbar {
    background-color: #e8e8e8;
    width: 4px; }

.cart-popup__img-wrapper img {
  width: 100%; }

.cart-popup.active-cart {
  display: block; }

.cart-popup__title {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 16px;
  border-bottom: 1px solid #F5F5F5; }

.cart-popup__good-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid #F5F5F5; }
  @media (any-hover: hover) {
    .cart-popup__good-item:hover .cart-popup__item-title {
      color: #DBA300; } }

.cart-popup__note {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ABABAB;
  margin-top: 20px; }

.cart-popup__title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 6px; }

.cart-popup__item-title {
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
  transition: .3s color; }

.cart-popup__counter {
  display: flex;
  align-items: center; }

.conter-input {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #7F818C;
  padding: 3px 13px;
  background: #fff;
  border: 1px solid #e9e8ee;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 32px;
  cursor: pointer;
  user-select: none; }

.buttonCountNumber {
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #2b2c2e;
  margin: 0 10px; }

.cart-popup__price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 14px; }
  .cart-popup__price-wrapper .price-old {
    padding-top: 2px; }

.cart-popup__img-wrapper {
  margin-right: 20px;
  display: block;
  width: 110px; }

.cart-cross {
  width: 25px; }

.cart-popup__content {
  width: 300px; }

.cart-popup__discount {
  display: flex;
  align-items: center; }

.cart-popup__img-wrapper {
  position: relative;
  margin-top: 20px; }

/* .cart-popup__discount-item {
    position: absolute;
    top: 10px;
} */
.cart-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }

.cart-total__text {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase; }

.cart-total__value {
  font-weight: 700;
  font-size: 18px; }

.cart-btn {
  width: 100%;
  margin-top: 20px; }

.cart-popup .cart-btn {
  padding: 0; }

.cart-popup a {
  width: 100%;
  padding: 14px; }

.cart-popup .cart-btn {
  padding: 0; }

.cart-popup a {
  width: 100%;
  padding: 14px; }

.cart-overall__list {
  padding: 21px 0; }
  @media (max-width: 767px) {
    .cart-overall__list {
      padding: 4px 0 20px; } }

.cart-overall__list-item {
  color: #7F818C; }
  .cart-overall__list-item:not(:last-child) {
    margin-bottom: 13px; }
  .cart-overall__list-item dt {
    font-size: 14px;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .cart-overall__list-item dt {
        grid-area: key; } }
  .cart-overall__list-item dd {
    font-size: 15px; }
    @media (min-width: 768px) {
      .cart-overall__list-item dd {
        grid-area: value; } }

.cart-overall__list-item, .cart-overall__box {
  display: grid;
  grid-template-columns: 110px 1fr max-content;
  column-gap: 12px;
  align-items: center; }
  @media (max-width: 767px) {
    .cart-overall__list-item, .cart-overall__box {
      grid-template-columns: 1fr max-content; } }
  @media (min-width: 768px) {
    .cart-overall__list-item, .cart-overall__box {
      grid-template-areas: '. key value'; } }

.cart-overall__box {
  padding: 19px 0;
  background-color: #F5F5F5;
  font-weight: 500; }
  @media (max-width: 767px) {
    .cart-overall__box {
      position: relative;
      padding: 18px 0; } }
  @media (max-width: 767px) {
    .cart-overall__box::before, .cart-overall__box::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #F5F5F5;
      width: 16px; } }
  .cart-overall__box::before {
    left: -16px; }
  .cart-overall__box::after {
    right: -16px; }

.cart-overall__box-text {
  font-size: 16px;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .cart-overall__box-text {
      grid-area: key; } }

.cart-overall__box-sum {
  font-size: 32px;
  line-height: 100%; }
  @media (max-width: 576px) {
    .cart-overall__box-sum {
      font-size: 26px;
      line-height: 130%; } }
  @media (min-width: 768px) {
    .cart-overall__box-sum {
      grid-area: value; } }

#catalog-goods .catalog-page__title {
  margin-top: 50px; }
  @media (max-width: 576px) {
    #catalog-goods .catalog-page__title {
      margin-top: 14px; } }

#catalog-goods .catalog-goods {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 20px; }
  @media (max-width: 870px) {
    #catalog-goods .catalog-goods {
      grid-template-columns: repeat(3, 1fr); } }
  @media (max-width: 576px) {
    #catalog-goods .catalog-goods {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 8px; } }
  #catalog-goods .catalog-goods img {
    transition: 0.35s; }

#catalog-goods .catalog-good__img-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  #catalog-goods .catalog-good__img-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px; }

#catalog-goods .catalog-good__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2b2c2e;
  margin-top: 16px;
  transition: 0.35s; }
  @media (max-width: 576px) {
    #catalog-goods .catalog-good__title {
      font-size: 14px;
      margin-top: 6px; } }

#catalog-goods .catalog-good__list {
  display: flex;
  flex-direction: column; }
  @media (max-width: 576px) {
    #catalog-goods .catalog-good__list {
      display: none; } }
  #catalog-goods .catalog-good__list a {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #7f818c; }

@media (min-width: 991px) {
  .catalog-good .catalog__good-link:hover .catalog-good__title {
    color: #dba300 !important;
    transition: 0.35s; }
  .catalog-good .catalog__good-link:hover img {
    transform: scale(1.02);
    transition: 0.35s; }
  .catalog-good__list a:hover {
    color: #dba300 !important; } }

#catalog-page {
  padding-top: 50px; }

.catalog-page {
  display: flex; }

.catalog-filter {
  width: 310px; }

.catalog-filter__title {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #2b2c2e;
  font-stretch: 70%;
  display: flex;
  align-items: center;
  cursor: pointer; }

.catalog-filter__title-link::before {
  content: '';
  height: 18px;
  width: 18px;
  display: flex;
  background: url("../images/arrow-left.svg") no-repeat center top/cover;
  margin-right: 3px; }

.catalog-filter__category-list {
  display: flex;
  flex-direction: column;
  padding-top: 6px; }

.catalog-filter__category-list_item {
  padding: 6px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C; }

.catalog-filter__title-wrapper,
.catalog-filter__mobile-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }

.catalog-filter__mobile-title-wrapper {
  display: none; }

.filter-type {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2b2c2e;
  cursor: pointer; }

.filter-type span {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ABABAB;
  margin-left: 6px; }

.catalog-filter__content {
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 0.35s; }

.show-more {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #DBA300;
  cursor: pointer;
  background: #fff;
  padding-right: 35px;
  padding-bottom: 6px;
  display: none;
  width: 100%; }

.show-more.active {
  display: block;
  position: absolute;
  bottom: -1px; }
  @media (max-width: 576px) {
    .show-more.active {
      bottom: 0; } }

.show-less {
  position: static !important;
  color: #DBA300;
  margin-top: 14px;
  text-decoration-line: underline;
  cursor: pointer;
  /* padding-top: 0 !important; */ }

.catalog-filter__item {
  margin-top: 30px; }

.catalog-filter__content .good-stats__size-wrapper {
  flex-wrap: wrap;
  padding-left: 1px; }
  .catalog-filter__content .good-stats__size-wrapper label {
    width: inherit;
    height: 36px;
    font-size: 14px;
    padding: 5px 11px; }

.catalog-filter__result {
  padding: 16px;
  background: #fff0c3;
  border-radius: 6px;
  margin-top: 30px; }

.catalog-filter__result-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2b2c2e;
  margin-bottom: 8px; }

.catalog-filter__btn-result {
  padding: 10px;
  background: #2b2c2e;
  border: 1px solid #2b2c2e;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-bottom: 10px; }

.catalog-filter__btn-show {
  padding: 10px;
  background: #2b2c2e;
  border: 1px solid #2b2c2e;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  width: 100%; }

.catalog-filter__title-wrapper svg {
  transition: 0.35s; }

.catalog-filter__title-wrapper.active svg {
  transform: rotate(180deg);
  transition: 0.35s; }

.catalog-content {
  margin-left: 135px;
  max-width: 1015px;
  width: 100%; }

.catalog-tags,
.catalog-tags__tabs-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }

.catalog-tags {
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e8ee; }

.catalog-tags__tabs-wrapper {
  margin-top: 20px;
  width: 89%; }

.catalog-tag,
.active-tag {
  margin-right: 8px;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  border-radius: 6px;
  margin-bottom: 8px;
  min-height: 40px; }

.catalog-tag.active {
  border: 1px solid #2b2c2e;
  background: #fff;
  pointer-events: none; }

.active-tag {
  border: 1px solid #e9e8ee;
  background: #fff;
  border-radius: 6px;
  display: flex; }

.active-tag::after {
  content: '';
  width: 16px;
  height: 16px;
  display: flex;
  background: url("../images/tag-cross.svg") no-repeat center top;
  margin-left: 4px; }

.catalog-filter__page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px; }

.catalog-filter__pagination-item:last-child .ui-selectmenu-button.ui-button {
  width: 73px; }

.catalog-filter__pagination-item select {
  display: none; }

.filter-catalog__goods {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px; }

.filter-catalog__goods-item {
  width: 325px; }

.pagination___show-more {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  text-align: center;
  cursor: pointer; }

.pagination___show-more {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  text-align: center;
  cursor: pointer; }

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }

.pagination ul {
  width: 100%;
  display: flex;
  padding: 8px; }

.pagination ul li {
  color: #2b2c2e;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease; }

.btn.numb.next.disabled {
  pointer-events: none; }

.btn.numb.prev.disabled {
  pointer-events: none; }

@media (max-width: 576px) {
  .pagination ul li.numb.next {
    margin-left: 3px; } }

.pagination ul li.numb {
  list-style: none;
  height: 40px;
  width: 40px;
  margin: 0 3px;
  line-height: 45px;
  border: 1px solid #e9e8ee;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2b2c2e;
  display: flex;
  justify-content: center;
  align-items: center; }

.pagination ul li.numb.first {
  margin: 0px 3px 0 -5px; }

.pagination ul li.dots {
  font-size: 22px;
  cursor: default; }

.pagination li.active {
  background: #fce194;
  border: 1px solid #fce194; }

.pagination ul li.numb span {
  position: relative;
  width: 100%;
  height: 100%; }

.pagination ul li.numb span a {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  line-height: 239%; }

.pagination ul li.btn {
  padding: 0; }

@media (min-width: 991px) {
  .pagination li.active,
  .pagination ul li.numb:hover,
  .pagination ul li:first-child:hover,
  .pagination ul li:last-child:hover {
    background: #fce194;
    border: 1px solid #fce194; } }

.mobile-filter__sort {
  display: none; }

.filter-btn__open {
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b2c2e;
  border-radius: 6px;
  padding: 12px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-bottom: 30px;
  display: none; }
  .filter-btn__open svg {
    margin-right: 8px; }

.remove-tags,
.remove-tags__mobile {
  display: none;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #ababab;
  cursor: pointer;
  margin-top: 33px; }

.remove-tags__mobile {
  margin-bottom: 20px;
  margin-top: 0; }

.remove-tags.active,
.remove-tags__mobile.active {
  display: flex; }

.catalog-tags__active {
  display: flex;
  align-items: flex-start; }

.show-more__tag {
  display: none;
  cursor: pointer; }

.catalog-filter__content .good-stats__size-item {
  line-height: 0;
  margin-bottom: 5px; }

@media (max-width: 1410px) {
  .catalog-content {
    margin-left: 50px;
    width: 75%; } }

@media (max-width: 576px) {
  .catalog-filter {
    display: none; }
  .catalog-content {
    margin-left: 0;
    width: 100%; }
  .catalog-page .container {
    padding: 0; }
  .catalog-filter__page {
    display: none; }
  .pagination___show-more {
    display: none; }
  .pagination-wrapper {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px; }
  .pagination ul {
    padding: 0; }
  .pagination ul li.next {
    margin-left: 9px; }
  .pagination ul li.prev {
    margin: 0; }
  .catalog-filter__pagination-item {
    margin-top: 30px;
    padding-bottom: 50px; }
  #catalog-page {
    padding-top: 0; }
  .filter-btn__open {
    display: flex; }
  .catalog-tags__active {
    flex-direction: column;
    height: 0; }
  .remove-tags {
    margin-top: 0; }
  .catalog-tags {
    position: relative; }
  .catalog-tags:not(.active) .catalog-tag:nth-child(1n + 4) {
    display: none; }
  .show-more__tag {
    display: block;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #dba300;
    background: #fff;
    margin-bottom: 15px; }
    .show-more__tag.is-visible {
      display: flex; }
  .catalog-tags {
    border: none; }
  .catalog-tags__wrapper {
    border-bottom: 1px solid #e9e8ee; }
  .catalog-tag.active {
    pointer-events: all; }
  .catalog-filter.popup {
    visibility: visible;
    display: flex;
    margin-left: -16px;
    opacity: 1; }
  .catalog-filter__container {
    background: #fff;
    padding: 16px 16px 48px;
    height: 100vh;
    overflow-y: scroll;
    width: 100%; }
  .catalog-filter__mobile-title-wrapper {
    display: flex;
    margin-bottom: 22px;
    align-items: center; }
  .catalog-filter__title-mobile {
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: #2b2c2e; }
  .mobile-filter__sortlist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #2b2c2e;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #2b2c2e;
    padding: 10px 16px; }
  .mobile-filter__sort-wrapper {
    padding: 16px 20px;
    border: 1px solid #2b2c2e;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
    display: none; }
    .mobile-filter__sort-wrapper .radio__box:checked ~ .radio__inner {
      border: none; }
    .mobile-filter__sort-wrapper .radio__box ~ .radio__inner {
      border: none;
      padding: 6px 0; }
  .mobile-filter__sort-wrapper.active {
    display: block;
    position: absolute;
    z-index: 999999999;
    width: 100%;
    background: #fff; }
  .mobile-filter__sortlist.active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 1px solid #fff; }
    .mobile-filter__sortlist.active svg {
      transform: rotate(180deg);
      transition: 0.35s; }
  .filter-value {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em; }
  .mobile-filter__sort {
    margin-top: 30px;
    position: relative;
    display: block; }
  .mobile-filter__sort-title {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #2b2c2e;
    margin-bottom: 14px; }
  .catalog-filter__content.active .good-stats__size-wrapper {
    margin-top: 2px; }
  /*   .catalog-filter__item:nth-child(2) .catalog-filter__content.active {
    height: 90px !important;
  } */ }

.catalog-filter__content .custom-checkbox ~ label:not(.error)::before {
  width: 20px;
  height: 20px; }

@media (max-width: 576px) {
  .catalog-content .page {
    padding-top: 30px; }
  .pagination-wrapper #sort-section {
    flex-basis: 100%;
    text-align: center; } }

.good-stats__size-wrapper .show-more.active {
  display: block;
  position: absolute;
  bottom: -21px;
  height: 37px;
  width: 100%;
  padding-right: 0;
  /*
  @media (min-width: 576px) {
    padding-top: 14px;
  } */ }
  @media (max-width: 576px) {
    .good-stats__size-wrapper .show-more.active {
      bottom: -18px; } }

@media (max-width: 450px) {
  .pagination ul li.numb {
    height: 35px;
    width: 35px; } }

@media (max-width: 410px) {
  .pagination ul li.numb {
    height: 35px;
    width: 35px; }
  .pagination ul li.dots {
    font-size: 15px;
    cursor: default;
    padding-top: 10px; } }

@media (max-width: 350px) {
  .pagination ul li.numb {
    height: 35px;
    width: 25px; } }

.catalog-filter__title_prev {
  text-transform: initial;
  font-size: 15px;
  margin-top: 7px; }

#catalog {
  display: none;
  position: absolute;
  width: 100%;
  top: calc(100% + var(--header-bottom-height));
  z-index: 20;
  transition: .3s top; }

#catalog.active {
  display: block; }

.catalog {
  background-color: #fff;
  box-shadow: 0px 7px 20px rgba(133, 133, 133, 0.12);
  display: flex; }

.catalog-item {
  padding: 17px 0; }

.catalog-first__menu {
  background-color: #F5F5F5;
  width: 412px; }

.catalog-second__menu {
  width: 586px; }

.catalog-first__list {
  list-style: none; }

.catalog-first__list-item {
  cursor: pointer; }

.catalog-third__list {
  list-style: none; }

.catalog-first__list-item a {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  padding: 13px 30px;
  display: block; }

.catalog-first__list-item.active {
  background: #fff;
  color: #2b2c2e; }

.catalog-first__list-item.active a {
  color: #2b2c2e; }

.catalog-first__list-item:hover {
  background: #fff; }

.catalog-second__list {
  position: absolute;
  display: none; }

.catalog-second__list.active {
  position: static;
  display: block; }

.catalog-second__list-item {
  padding: 13px 30px;
  cursor: pointer;
  width: inherit !important;
  position: relative; }

.catalog-second__list-item a {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  display: flex;
  flex-direction: column;
  max-width: 83%; }

.catalog-third__list-item {
  padding: 13px 30px;
  cursor: pointer; }

ul.catalog-second__list .catalog-third__list-item {
  width: 340px; }

.catalog-second__list-item_text {
  padding-top: 12px;
  text-align: center; }

.catalog-third__list-item a {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C; }

.catalog-third__menu {
  width: 340px; }

.catalog-third__list-item_text {
  padding-top: 12px;
  text-align: center; }

.catalog-third__list-item_img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  margin-top: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  .catalog-third__list-item_img-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%; }

.catalog-second__list-item_img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  margin-top: 10px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  .catalog-second__list-item_img-wrapper img {
    position: absolute;
    width: 100%;
    height: 100%; }

.catalog-third__list {
  position: absolute;
  display: none; }

.catalog-third__list.active {
  display: block;
  position: static; }

.catalog-second__list-item.active {
  color: #DBA300;
  display: flex;
  align-items: center;
  width: max-content; }

.catalog-second__list-item {
  display: flex;
  align-items: center;
  width: max-content; }

.catalog-second__list-item.active a {
  color: #DBA300; }

.catalog-second__list-item.active::after {
  content: '';
  display: flex;
  height: 10px;
  width: 30px;
  background: url(../images/arrow-gold.svg) no-repeat center center;
  margin-left: 20px; }

.catalog-third__list-item:hover a {
  color: #DBA300; }

@media (max-width: 1400px) {
  .catalog {
    width: 97%; }
  .catalog-first__menu {
    width: 300px; }
  .catalog-second__menu {
    width: 640px; } }

.header-main #catalog {
  height: 64vh;
  overflow: auto;
  box-shadow: 0px 7px 20px rgba(133, 133, 133, 0.12); }

.header-main.fixed #catalog {
  height: 82vh; }

#catalog li {
  list-style-type: none !important; }

.header-main.fixed #catalog::-webkit-scrollbar {
  width: 0; }

.checkout {
  padding-top: 60px; }
  .checkout__container {
    width: 900px;
    max-width: 100%; }
  .checkout__item:not(:last-child) {
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .checkout__item:not(:last-child) {
        margin-bottom: 56px; } }
  .checkout__fieldsets:not(:last-child) {
    margin-bottom: 36px; }
  .checkout__item-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      .checkout__item-title {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 20px; } }
  @media (min-width: 1201px) {
    .checkout__item-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr); } }
  .checkout__tabs {
    display: grid;
    gap: 11px;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .checkout__tabs {
        grid-auto-columns: max-content;
        grid-auto-flow: column; } }
    @media (max-width: 767px) {
      .checkout__tabs {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 36px; } }
    @media (max-width: 576px) {
      .checkout__tabs {
        gap: 8px; } }
  .checkout__tab {
    padding: 15px; }
    @media (min-width: 768px) {
      .checkout__tab {
        min-width: 216px; } }
    @media (max-width: 576px) {
      .checkout__tab {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px; } }
  .checkout__part {
    display: none; }
    @media (min-width: 768px) {
      .checkout__part {
        gap: 60px 20px; } }
    @media (max-width: 767px) {
      .checkout__part > *:not(:last-child) {
        margin-bottom: 36px; } }
    @media (min-width: 768px) {
      .checkout__part {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 768px) {
      .checkout__part.active {
        display: grid; } }
  .checkout__part-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      .checkout__part-title {
        font-size: 14px;
        line-height: 130%;
        margin-bottom: 24px; } }
  .checkout__fields {
    display: grid;
    gap: 20px; }
    @media (max-width: 576px) {
      .checkout__fields {
        gap: 16px 8px; } }
    @media (min-width: 576px) {
      .checkout__fields--col-2 {
        grid-template-columns: repeat(2, 1fr); } }
    .checkout__fields--col-4 {
      grid-template-columns: repeat(4, 1fr); }
      @media (max-width: 767px) {
        .checkout__fields--col-4 {
          grid-template-columns: repeat(2, 1fr); } }
      .checkout__fields--col-4 > *:nth-child(1), .checkout__fields--col-4 > *:nth-child(2) {
        grid-column: span 2; }
    .checkout__fields--col-2, .checkout__fields--col-4 {
      row-gap: 30px; }
      @media (max-width: 576px) {
        .checkout__fields--col-2, .checkout__fields--col-4 {
          row-gap: 8px; } }
  .checkout__field .ui-selectmenu-button.ui-button {
    width: 100%; }
  .checkout__input {
    width: 100%; }
  .checkout__small-text {
    display: block;
    margin-top: 20px; }
  .checkout__agrees {
    margin-top: 36px; }
  .checkout__agree {
    position: relative;
    display: flex;
    align-items: center; }
    .checkout__agree:not(:last-child) {
      margin-bottom: 16px; }
  .checkout__btn:active, .checkout__btn:focus {
    background-color: transparent;
    border-color: #2b2c2e; }
  .checkout__btn.hide {
    display: none; }

#pickup-date-menu .ui-menu-item:first-child {
  display: none; }

.pickup-field {
  display: none; }
  .pickup-field.active {
    display: block; }
  .pickup-field__input-wrap {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 13px; }
  .pickup-field__input {
    width: 100%;
    padding: 14px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase; }
  @media (min-width: 768px) {
    .pickup-field__btn {
      position: absolute;
      left: calc(100% + 20px);
      top: 50%;
      transform: translateY(-50%);
      width: max-content; } }
  @media (max-width: 767px) {
    .pickup-field__btn {
      margin-top: 12px; } }
  @media (max-width: 576px) {
    .pickup-field__btn {
      width: 100%; } }
  .pickup-field__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -6px;
    font-size: 15px;
    line-height: 140%;
    color: #7F818C; }
  .pickup-field__info-item {
    display: flex;
    flex-direction: column;
    padding: 6px; }
    @media (max-width: 576px) {
      .pickup-field__info-item {
        width: 100%; } }
    @media (any-hover: hover) {
      .pickup-field__info-item a:hover {
        color: #DBA300; } }

.checkout-total {
  display: grid;
  align-items: baseline;
  gap: 20px; }
  @media (max-width: 767px) {
    .checkout-total {
      gap: 24px; } }
  @media (min-width: 768px) {
    .checkout-total {
      grid-template-columns: 1fr repeat(2, minmax(210px, max-content));
      grid-template-areas: 'sum promocode submit'; } }
  @media (min-width: 768px) {
    .checkout-total {
      padding-top: 30px;
      border-top: 1px solid #F5F5F5; } }
  @media (min-width: 768px) {
    .checkout-total__btn-wrap {
      grid-area: promocode; } }
  .checkout-total__promocode {
    display: none; }
    .checkout-total__promocode .input {
      font-size: 13px; }
  .checkout-total__promocode-btn {
    width: 100%; }
  .checkout-total__submit {
    height: initial; }
    @media (min-width: 768px) {
      .checkout-total__submit {
        grid-area: submit; } }
  .checkout-total__overall {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .checkout-total__overall {
        grid-area: sum; } }
  .checkout-total__overall-sum {
    font-weight: 500;
    font-size: 32px;
    margin-left: 13px; }
    @media (max-width: 767px) {
      .checkout-total__overall-sum {
        font-size: 26px;
        line-height: 130%;
        margin-left: auto; } }

@media (max-width: 410px) {
  #pickup,
  #pickup span-2:nth-child(2) input {
    width: 380px; } }

@media (max-width: 390px) {
  #pickup,
  #pickup span-2:nth-child(2) input {
    width: 360px; } }

@media (max-width: 370px) {
  #pickup,
  #pickup span-2:nth-child(2) input {
    width: 330px; } }

@media (max-width: 350px) {
  #pickup,
  #pickup span-2:nth-child(2) input {
    width: 300px; } }

.tape-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  bottom: 30px;
  width: 100%;
  z-index: 999; }

.yellow-tape {
  background-color: #FFF0C4;
  border-radius: 14px;
  padding: 14px 14px 14px 27px;
  width: 1360px;
  display: flex;
  align-items: center; }
  @media (max-width: 576px) {
    .yellow-tape {
      padding: 16px 13px; } }
  .yellow-tape__text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em; }
  .yellow-tape__actions {
    display: flex;
    margin-left: auto;
    align-items: center; }
    @media (max-width: 576px) {
      .yellow-tape__actions {
        width: 100%; } }
  .yellow-tape__buttons {
    display: flex; }
    @media (max-width: 576px) {
      .yellow-tape__buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        width: 100%; } }
  .yellow-tape__btn {
    font-weight: 500;
    font-size: 14px;
    padding: 13px; }
    @media (min-width: 576px) {
      .yellow-tape__btn {
        padding-right: 58px;
        padding-left: 58px; } }
    .yellow-tape__btn:active, .yellow-tape__btn:focus {
      background-color: #2b2c2e;
      border-color: #2b2c2e; }
  .yellow-tape__btn + .yellow-tape__btn {
    margin-left: 10px; }
    @media (max-width: 576px) {
      .yellow-tape__btn + .yellow-tape__btn {
        margin-left: 0; } }
  .yellow-tape__link-btn {
    margin-right: 40px; }
    .yellow-tape__link-btn .btn:active, .yellow-tape__link-btn .btn:focus {
      border-bottom: 1px solid #2b2c2e; }

.cookies {
  z-index: 101;
  opacity: 0; }
  @media (max-width: 576px) {
    .cookies .yellow-tape__actions {
      align-items: flex-start; } }

.cart-auth-tape {
  margin-top: 50px; }

.auth-tape {
  z-index: 100; }
  .auth-tape--fit-width {
    max-width: 1090px; }
  @media (max-width: 576px) {
    .auth-tape .yellow-tape__actions {
      flex-direction: column-reverse; } }
  .auth-tape .yellow-tape__buttons {
    grid-template-columns: unset; }
  @media (max-width: 576px) {
    .auth-tape .yellow-tape__link-btn {
      margin-top: 20px;
      margin-right: 0; } }

@media (max-width: 1400px) {
  .yellow-tape {
    width: 100%; } }

@media (max-width: 1170px) {
  .yellow-tape {
    flex-direction: column;
    align-items: flex-start; }
    .yellow-tape__actions {
      margin-left: 0;
      margin-top: 14px; } }

@media (max-width: 576px) {
  .yellow-tape__text {
    font-size: 14px; }
  .yellow-tape__actions {
    flex-direction: column; }
  .yellow-tape__link-btn {
    font-size: 14px;
    margin-bottom: 16px;
    display: block; }
  .yellow-tape {
    bottom: 100px; } }

#cookies {
  display: none; }

#details-good {
  padding-top: 50px; }
  #details-good .details-good__slide img, #details-good .detail-good__slider_slide img {
    opacity: 1 !important; }

.details-good {
  display: flex; }

.details-good__item:first-child {
  width: 500px; }

.details-good__img-links {
  display: flex; }

.details-good__img-link {
  cursor: pointer; }

.details-good__slider-wrapper {
  position: relative;
  width: 500px; }

.popup-fancy__fancy-wrapper .details-good__slider-wrapper {
  width: 711px;
  max-height: 711px; }

.arrow-good {
  position: absolute;
  top: 42%;
  z-index: 2;
  cursor: pointer; }

@media (min-width: 920px) {
  .details-good .good-stats__about {
    display: flex; }
    .details-good .good-stats__about .goods-stast__mobile-label {
      position: unset;
      flex-shrink: 0; } }

.arrow-good__prev {
  left: -18%; }

.arrow-good__next {
  right: -18%; }

.details-good__slide .sale {
  padding: 8px; }

.detail-good__slider_slide.swiper-slide {
  border-radius: 0%;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.35s; }

.detail-good__slider_slide.swiper-slide-thumb-active {
  opacity: 1;
  transition: 0.35s; }

.details-good__slide.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  aspect-ratio: 1 / 1; }

@media (min-width: 1024px) {
  .details-good__slide.swiper-slide {
    opacity: 0; } }

.details-good__slide.swiper-slide img {
  height: auto;
  width: auto; }

.details-good__slide.swiper-slide.swiper-slide-active {
  opacity: 1; }

.details-good__item:last-child {
  margin-left: 190px;
  position: relative;
  width: 100%; }

.good-stats__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-bottom: 8px; }

.good-stats__status {
  margin-bottom: 20px; }

.good-stats__price-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px; }

.good-stats__price-wrapper-box {
  display: flex;
  align-items: flex-end;
  height: min-content; }

.good-stats__price {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #2b2c2e;
  margin-right: 8px; }

.good-stats__price_old {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ABABAB;
  text-decoration: line-through; }

.goods-stast__mobile-label {
  position: absolute;
  right: 0;
  top: 140px; }

@media (max-width: 920px) {
  .good-stats__about .goods-stast__mobile-label {
    display: none; } }

.good-stats__about-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e;
  margin-bottom: 6px; }
  .good-stats__about-item span {
    color: #7F818C; }
  .good-stats__about-item a:hover span {
    color: #dba300; }

.good-stats__about-item span:last-child {
  color: #2b2c2e; }

.good-stats__color {
  margin-top: 20px; }

.good-stats__color-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  margin-bottom: 7px; }

.good-stats__color-wrapper {
  display: flex;
  align-items: center;
  margin-top: -15px; }

.good-stats__size-item input[type="radio"] {
  width: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 0; }

.good-stats__color-item input[type="radio"],
.good-stats__size-item input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }

.good-stats__color-item input[type="radio"]:checked + label {
  outline: 1px solid #f3c25a; }

.good-stats__color-item.checked a {
  outline: 1px solid #f3c25a; }

.good-stats__color-item input[type="radio"][disabled] + label, .good-stats__color-item.disabled a {
  border: 6px solid #F5F5F5;
  outline: 1px solid #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center; }

.good-stats__color-item input[type="radio"][disabled] + label::after, .good-stats__color-item.disabled a::after {
  content: '';
  border: 1px solid #F5F5F5;
  transform: rotate(45deg);
  display: flex;
  position: absolute;
  width: 51px;
  height: 1px;
  pointer-events: visible; }

.good-stats__color-item label,
.good-stats__size-item label,
.good-stats__color-item a {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  cursor: pointer;
  border: 6px solid #fff;
  outline: 1px solid #e9e8ee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e;
  background-size: cover; }

.good-stats__color-item a {
  margin-top: 20px; }

.good-stats__size-item label {
  border: 1px solid #e9e8ee;
  outline: none;
  width: max-content; }

.good-stats__size-item label {
  width: 100%;
  padding: 10px; }

.good-stats__color-item,
.good-stats__size-item {
  margin-right: 8px;
  position: relative; }

.color-status {
  position: absolute;
  background: #fff;
  width: max-content;
  top: -25px;
  display: none; }

.good-stats__color-item label:hover .color-status, .good-stats__color-item a:hover .color-status {
  display: block; }

.color-status {
  padding: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #2b2c2e;
  margin-bottom: 2px;
  box-shadow: 0px 7px 8px rgba(133, 133, 133, 0.12);
  border: 1px solid #F5F5F5;
  top: -10px;
  left: 25px;
  z-index: 2; }
  .color-status .good-stats__status_deny {
    font-size: 13px;
    margin-bottom: 0; }
  .color-status .good-stats__status {
    font-size: 13px;
    margin-bottom: 0; }

.good-stats__size {
  margin-top: 20px; }

.good-stats__size-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e; }

.good-stats__size-wrapper {
  margin-top: 7px;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap; }

.buttonCountNumber.conter-input__good {
  font-size: 18px; }

.good-stats__size-item input[type="radio"]:checked + label {
  border: 1px solid #fce194;
  background: #fce194;
  border-radius: 9px; }

.good-stats__size-item input[type="radio"][disabled] + label {
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #ABABAB; }

.good-stats__size-item input[type="checkbox"]:checked + label {
  border: 1px solid #fce194;
  background: #fce194;
  border-radius: 9px; }

.good-stats__size-item input[type="checkbox"][disabled] + label {
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #ABABAB; }

.count-size {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #2b2c2e;
  margin-top: 12px;
  cursor: pointer;
  width: fit-content; }

.good-stats__count {
  margin-top: 30px;
  border-top: 1px solid #e9e8ee;
  padding-top: 30px;
  display: flex;
  align-items: center; }

.conter-input__good-number {
  font-size: 18px; }

.good-popup__counter {
  border: 1px solid #e9e8ee;
  border-radius: 6px; }

.conter-input__good {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #2b2c2e;
  padding: 15px;
  cursor: pointer;
  user-select: none; }

.good-btn {
  padding: 14px 40px;
  margin-left: 16px; }

.add-to-cart {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-left: 30px;
  cursor: pointer; }

.detail-good__item:first-child {
  display: none; }

.goods-icon__right .favorite-btn.favorite svg path {
  stroke: #F95E5E;
  fill: #F95E5E; }

.details-good__slide {
  cursor: pointer; }

@media (max-width: 1560px) {
  .arrow-good__prev {
    left: -8%; }
  .arrow-good__next {
    right: -8%; } }

@media (max-width: 1460px) {
  .arrow-good__prev {
    left: -3%; }
  .arrow-good__next {
    right: -3%; } }

@media (max-width: 1460px) {
  .arrow-good__prev {
    left: 0; }
  .arrow-good__next {
    right: 0; } }

@media (max-width: 1220px) {
  .good-stats__count {
    flex-wrap: wrap; }
  .add-to-cart {
    margin-left: 0;
    margin-top: 24px; }
  .goods-stast__mobile-label {
    top: 90px; } }

@media (max-width: 1080px) {
  .details-good__item:last-child {
    margin-left: 40px; } }

@media (max-width: 920px) {
  .details-good {
    flex-direction: column;
    align-items: center; }
  .details-good__item:last-child {
    margin-left: 0; }
  .arrow-good__prev {
    left: -18%; }
  .arrow-good__next {
    right: -18%; }
  .detail-good__item:first-child {
    display: block;
    width: 100%; }
  .good-stats .detail-good__head {
    display: none; }
  .goods-stast__mobile-label {
    position: static;
    margin-top: 10px; }
  .add-to-cart {
    margin-left: 30px;
    margin-top: 0; } }

@media (max-width: 700px) {
  .arrow-good__prev {
    left: 0; }
  .arrow-good__next {
    right: 0; }
  .details-good__slider-wrapper {
    width: 450px;
    padding-bottom: 25px; }
  .details-good__slider-wrapper .swiper-pagination-bullet {
    width: 76px;
    height: 76px; }
  .details-good__slider-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 76px;
    height: 76px; }
  .goods-stast__mobile-label {
    top: 200px; }
  .details-good__item:nth-child(2) {
    width: 100%; }
  .details-good__slider-wrapper {
    width: auto; } }

@media (max-width: 576px) {
  #details-good {
    padding-top: 14px; } }

@media (max-width: 470px) {
  .good-stats__title {
    margin-bottom: 10px; }
  .good-stats__price {
    font-size: 26px;
    line-height: 30px; }
  .detail-good__head .good-stats__status {
    margin-bottom: 0; }
  .goods-stast__mobile-label {
    position: static;
    margin-left: auto; }
  .good-stats__price-wrapper {
    width: 100%;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 17px; }
  .add-to-cart {
    font-size: 14px;
    margin-top: 24px;
    margin-left: 0; }
  .good-stats__about-item {
    font-style: 14px; }
  .good-stats__about-item span {
    display: block;
    margin-bottom: 4px; }
  .good-stats__about {
    margin-top: 16px;
    margin-bottom: 20px; }
  .good-stats__color-title {
    margin-bottom: -5px; }
  .good-stats__size-wrapper {
    margin-top: -5px; }
  .good-stats__count {
    margin-top: 24px; }
  .good-btn {
    width: 190px;
    padding: 14px 0; }
  .conter-input__good {
    padding: 13px; }
  .good-stats__size-wrapper {
    flex-wrap: wrap; } }

@media (max-width: 360px) {
  .details-good__slider-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 60px;
    height: 60px; }
  .details-good__slider-wrapper .swiper-pagination-bullet {
    width: 60px;
    height: 60px; }
  .good-btn {
    width: 168px;
    font-size: 12px;
    margin-left: 11px; }
  .conter-input__good {
    padding: 12px; } }

#details-good .label {
  padding: 6px; }

.color-status .status.not-available {
  color: #F95E5E; }

.color-status .status.not-available::before {
  background-image: url("../images/red-cross.svg"); }

.detail-good__slider_small {
  margin-top: 10px; }

.detail-good__slider_small_fancy {
  margin-top: 24px; }

.details-good__slider_fancy img {
  width: auto;
  height: auto; }

.fancy-popup__wrapper .swiper-slide {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 1 / 1; }

@media (max-width: 576px) {
  .detail-good__slider_small_fancy {
    margin-top: 11px; } }

.fancy-popup.active {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1; }
  .fancy-popup.active .details-good__slider-wrapper {
    position: static; }
  .fancy-popup.active .mobile-cross img {
    width: 24px;
    height: 24px; }

.fancy-popup__wrapper {
  background: #fff;
  padding: 40px;
  position: relative;
  width: 80vw;
  height: 95vh;
  overflow: auto; }

.popup-fancy__fancy-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }

.fancy-popup .mobile-cross {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2; }

.fancy-popup__title-wrapper {
  display: flex;
  margin-bottom: 30px; }

.fancy-popup__title {
  font-weight: 500;
  font-size: 44px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  width: 90%; }

.fancy-popup .arrow-good__next {
  right: 0; }

.fancy-popup .arrow-good__prev {
  left: 0; }

@media (max-width: 991px) {
  .fancy.row-4 {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px; }
  .fancy-popup .arrow-good__next {
    right: 0; }
  .fancy-popup .arrow-good__prev {
    left: 0; }
  .fancy-popup__title-wrapper {
    margin-bottom: 20px; }
  .fancy-title {
    font-size: 16px; } }

@media (max-width: 700px) {
  .fancy-popup__wrapper {
    padding: 20px;
    height: 75vh; }
    .fancy-popup__wrapper .arrow-good {
      top: 33%; } }

@media (max-width: 576px) {
  .fancy.row-4 {
    grid-template-columns: repeat(1, 1fr); }
  .popup-fancy__fancy-wrapper {
    display: block; }
  .fancy-popup__title {
    font-size: 18px;
    line-height: 130%; } }

.eq_fancy {
  width: 100%;
  height: 50vh; }

.fancy-pagination ul {
  justify-content: center;
  margin-top: 6px; }

.detail-good__slider_small_fancy .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
  cursor: pointer; }

.detail-good__slider_small_fancy .swiper-slide-thumb-active {
  opacity: 1; }

@media (max-width: 576px) {
  .fancy-popup__wrapper {
    height: 51vh !important; } }

.fancybox__carousel .fancybox__slide .fancybox__content {
  max-width: 100%; }

.carousel__button.fancybox__button--slideshow, .carousel__button.fancybox__button--fullscreen {
  display: none !important; }

.faq {
  margin-top: 50px;
  display: flex;
  justify-content: space-between; }

.faq ~ .pagination {
  margin-top: 20px; }

.faq-item__wrapper:first-child {
  width: 901px; }

.faq-item__wrapper:last-child {
  width: 325px; }

.faq-item {
  padding: 30px 0;
  border-bottom: 1px solid #e9e8ee; }

.faq-item:first-child {
  border-top: 1px solid #e9e8ee; }

.faq-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }
  .faq-title span {
    max-width: 785px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4; }
  .faq-title svg {
    transition: 0.35s; }

.faq-title.active svg {
  transform: rotate(180deg);
  transition: 0.35s; }

.faq-title.active span {
  display: block; }

@media (min-width: 991px) {
  .faq-title:hover {
    color: #dba300; } }

.faq-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7f818c;
  height: 0;
  overflow: hidden;
  transition: 0.35s; }
  .faq-content ul {
    list-style: inside;
    margin-left: 10px; }
    .faq-content ul li {
      margin-top: 20px; }

.faq-content.active {
  height: auto;
  margin-top: 30px; }

.faq-articles__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  padding-bottom: 30px;
  border-bottom: 1px solid #e9e8ee; }

.faq-article__link-wrapper {
  padding: 30px 0;
  border-bottom: 1px solid #e9e8ee; }

.faq-articel__link {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #2b2c2e; }

.faq-article__link-wrapper:hover .faq-articel__link {
  color: #DBA300; }

.faq-articel__date {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ababab;
  margin-top: 16px; }

@media (max-width: 1275px) {
  .faq-item__wrapper:first-child {
    margin-right: 20px; } }

@media (max-width: 1160px) {
  .faq {
    flex-direction: column; }
  .faq-item__wrapper:first-child {
    width: 100%;
    margin-bottom: 56px; } }

@media (max-width: 576px) {
  .faq {
    margin-top: 30px; }
  .faq-title {
    font-size: 16px;
    align-items: flex-start; }
    .faq-title span {
      max-width: 280px; }
  .faq-content {
    font-size: 15px; }
  .faq-item {
    padding: 20px 0; }
  .faq-content ul {
    margin-top: 20px; }
  .faq-content ul li {
    margin-top: 1px; }
  .faq-article__link-wrapper {
    padding: 20px 0; }
  .faq-articel__date {
    margin-top: 10px; } }

#footer-menu {
  padding: 20px 0;
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 10; }

.footer-menu {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.footer-menu__item a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #2b2c2e;
  margin-top: 4px; }

.footer-menu__item.active a {
  color: #DBA300; }

.footer-menu__item.active svg path {
  fill: #DBA300; }

@media (max-width: 576px) {
  #footer-menu {
    display: block;
    box-shadow: 7px 0px 40px rgba(133, 133, 133, 0.12); } }

#footer {
  padding-top: 120px; }

.footer-top {
  padding-top: 47px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.footer-top__logo img {
  width: 147px;
  height: 63px; }

.footer-phone__number {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer; }
  .footer-phone__number svg {
    transition: 0.35s; }

.footer-phone__number.active-phone .footer-phone__number_text {
  color: #DBA300; }

.footer-phone__number.active-phone svg {
  transform: rotate(180deg);
  transition: 0.35s; }

.footer-phone__number.active-phone svg path {
  fill: #DBA300; }

.footer-phone__number_text {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #2b2c2e;
  padding-right: 6px; }

.footer-top__mail {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #2b2c2e; }

.footer-main {
  margin-top: 36px;
  display: flex;
  justify-content: space-between; }

.footer-top__social {
  margin-right: 7%; }

.footer-top__social a {
  margin-right: 10px; }

.footer-main__list {
  display: flex;
  flex-direction: column; }

.footer-main__list-item {
  font-weight: 400;
  font-size: 13px;
  color: #7F818C; }
  .footer-main__list-item:not(:last-child) {
    margin-bottom: 16px; }

.footer-main__list-item:hover {
  color: #DBA300; }

.footer-form__title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px; }
  @media (max-width: 575px) {
    .footer-form__title .footer-form__title {
      margin-top: 15px; } }

.footer-form {
  display: flex;
  flex-direction: column; }

.footer-form__label {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .footer-form__label div {
    width: 300px;
    max-width: 100%; }

.footer-form__label input {
  margin-right: 6px; }

.footer-pay__title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 45px;
  margin-bottom: 12px; }

.footer-pay__img {
  max-width: 324px; }

.footer-bottom {
  margin-top: 47px;
  padding-top: 30px;
  padding-bottom: 40px;
  border-top: 1px solid #e9e8ee;
  color: #7F818C;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.footer-bottom__item:first-child {
  font-size: 13px; }

.footer-bottom__item:nth-child(2) {
  font-size: 11px;
  max-width: 600px;
  margin-left: 60px; }

.footer-bottom__item:nth-child(3) {
  margin-right: 10%; }

.footer-main__list-title {
  margin-bottom: 20px;
  font-size: 14px; }

.footer-main__list-title svg {
  display: none; }

.footer-main-list__reward-title {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 10px; }

.footer-main-list__reward-text {
  font-size: 13px;
  color: #ABABAB;
  margin-top: 8px; }

.footer-main__list:first-child {
  max-width: 140px;
  margin-right: 70px; }

.footer-mobile__block {
  display: none;
  margin-bottom: 30px; }

.footer-mobile__content-wrapper {
  width: 180px;
  margin-left: 12px; }

.footer-mobile__content-wrapper .footer-main-list__reward-title {
  font-size: 12px; }

.footer-mobile__content-wrapper .footer-main-list__reward-text {
  font-size: 13px; }

.footer-form__label a {
  color: #DBA300;
  text-decoration: underline;
  margin-left: 3px; }

.foore-main__list-links a {
  display: block; }

.arrow-to-top {
  position: fixed;
  right: 50px;
  bottom: 157px;
  cursor: pointer;
  display: none;
  z-index: 5; }
  @media (max-width: 575px) {
    .arrow-to-top {
      display: none !important; } }

@media (any-hover: hover) {
  .arrow-to-top:hover svg rect {
    stroke: #DBA300; }
  .arrow-to-top:hover svg path {
    fill: #DBA300; } }

.footer-pay__mobile {
  display: none; }

.footer-phone__popup {
  position: absolute;
  background: #fff;
  box-shadow: 0px 7px 7px rgba(133, 133, 133, 0.12);
  z-index: 2;
  width: 290px;
  top: 25px;
  display: none;
  margin-top: 3px; }

.footer-phone__popup.active-phone {
  display: block; }

@media (max-width: 1250px) {
  .footer-main {
    padding-left: 0; } }

@media (max-width: 1070px) {
  .footer-main__list:first-child {
    margin-right: 0; } }

@media (max-width: 991px) {
  .footer-bottom {
    flex-wrap: wrap; }
  .footer-bottom__item {
    margin-bottom: 20px; } }

@media (max-width: 870px) {
  .footer-main {
    flex-wrap: wrap; }
  .footer-top {
    flex-wrap: wrap; }
  .footer-top__logo {
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .footer-bottom__item:nth-child(2) {
    margin-left: 0; } }

@media (max-width: 576px) {
  #footer {
    padding-bottom: 60px;
    padding-top: 56px; }
  .header-bottom__navbar-item:last-child {
    display: none; }
  .footer-top {
    flex-direction: column;
    padding-top: 0; }
  .footer-bottom {
    border: none;
    margin-top: 0; }
  .footer-bottom__item:first-child br {
    display: none; }
  .footer-mobile__block {
    display: flex; }
  .footer-top__mail {
    margin: 20px 0; }
  .footer-form__wrapper {
    order: -1;
    margin-bottom: 13px;
    width: 100%; }
  .footer-form__wrapper.active-phone {
    visibility: hidden; }
  .footer-main {
    position: relative;
    padding-bottom: 115px; }
  .footer-pay {
    position: absolute;
    bottom: 0; }
  .footer-main__list:first-child {
    display: none; }
  .foore-main__list-links {
    height: 0;
    overflow: hidden; }
  .footer-top__social {
    width: 100%; }
  .footer-top__social a {
    margin-right: 8px; }
  .footer-top__social a img {
    width: 48px; }
  .footer-mob__tab {
    width: 100%;
    z-index: 1;
    transition: 0.35s; }
    .footer-mob__tab svg {
      transition: 0.35s; }
  .footer-main__list-title {
    display: flex;
    align-items: center;
    margin-left: 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #7F818C;
    margin-left: 0;
    padding: 16px 0;
    border-bottom: 1px solid #e9e8ee;
    margin-bottom: 0; }
  .footer-main__list-title svg {
    display: flex; }
  .footer-mob__tab.active .footer-main__list-title {
    color: #DBA300; }
    .footer-mob__tab.active .footer-main__list-title svg {
      transform: rotate(180deg);
      transition: 0.35s; }
    .footer-mob__tab.active .footer-main__list-title svg path {
      fill: #DBA300; }
  .footer-mob__tab.active .foore-main__list-links {
    height: auto;
    transition: 0.35s;
    padding-top: 10px; }
  .arrow-to-top {
    bottom: 106px;
    right: 15px; }
  .footer-pay {
    display: none; }
  .footer-pay__mobile {
    display: block;
    width: 100%; }
  .footer-pay__img {
    max-width: 100%; }
  /*     .footer-pay__img img {
        max-width: 100%;
    } */
  .footer-main {
    padding-bottom: 0; }
  .footer-pay__title {
    margin-top: 30px; } }

@media (max-width: 330px) {
  .footer-top__social a img {
    width: 45px; } }

.footer-form__id {
  order: -1; }

.footer-form__label {
  flex-wrap: wrap;
  align-items: flex-start; }

#good-detail__slider {
  padding-top: 120px; }

.good-detail__slider-tabs {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e9e8ee; }

.good-detail__slider-tab {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #2b2c2e;
  padding: 14px 20px;
  cursor: pointer; }

.good-detail__slider-tab:hover {
  color: #DBA300; }

.good-detail__slider-tab.active {
  color: #DBA300;
  border: 1px solid #DBA300;
  border-radius: 6px; }

.good-detail__slider-content .goods-slider {
  display: none; }

.good-detail__slider-content .goods-slider.active {
  display: block; }

@media (max-width: 576px) {
  #good-detail__slider {
    padding-top: 56px; }
  #good-detail__slider .goods-slider {
    margin-top: 30px; }
  #good-detail__slider .title {
    width: 210px;
    font-size: 24px; }
  .good-detail__slider-tab {
    padding: 14px 12px;
    font-size: 12px; }
  .good-detail__slider-tabs {
    margin-top: 20px;
    padding-bottom: 0;
    border: none; } }

.good-detail__tab {
  padding-top: 60px; }
  .good-detail__tab ol, .good-detail__tab ul {
    padding-left: 20px; }

.good-detail__tab-links {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e8ee; }

.good-detail__tab-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-right: 60px;
  cursor: pointer;
  width: max-content; }

@media (min-width: 991px) {
  .good-detail__tab-link:hover {
    color: #DBA300; } }

.good-detail__tab-text {
  padding-top: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  width: 900px; }
  .good-detail__tab-text span {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase;
    color: #2b2c2e;
    margin-bottom: 8px; }

.good-detail__tab-content_buttons {
  display: flex;
  margin-bottom: 20px; }

.button-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  padding: 11px 26px;
  display: flex;
  align-items: center;
  border-radius: 6px; }
  .button-content svg {
    margin-right: 6px; }

.button-content:first-child {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.button-content:last-child {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.button-content.active {
  color: #fff;
  background: #2b2c2e; }
  .button-content.active svg path {
    fill: #fff; }
  .button-content.active svg circle {
    fill: #2b2c2e; }

.good-detail__tab-content_tabs {
  margin-top: 30px; }
  @media (max-width: 767px) {
    .good-detail__tab-content_tabs {
      margin-top: 20px; } }

.good-detail__tab-content_table-item {
  display: flex;
  justify-content: space-between; }

.good-detail__tab-content_table-title {
  color: #7F818C; }

.good-detail__tab-content_table-text {
  color: #2b2c2e; }

.good-detail__tab-content_table-title,
.good-detail__tab-content_table-text {
  width: 270px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%; }

.good-detail__tab-content_table-item {
  padding: 26px 0;
  border-bottom: 1px solid #e9e8ee; }

.stock {
  color: #2b2c2e; }

.stock::before {
  background: url("../images/stock.svg") no-repeat center center; }

.deny {
  color: #ABABAB; }

.deny::before {
  background: url("../images/deny.svg") no-repeat center center; }

.good-detail__tab-content_item {
  display: none; }

.good-detail__tab-content_item.active {
  display: block; }

.good-detail__tab-link.active {
  color: #DBA300;
  border-color: #d6a119;
  border-bottom: 3px solid; }

.good-detail__tab-content_item:not(:first-child) {
  margin-top: 30px; }

.good-detail__tab-content_tab-item {
  display: none; }

.good-detail__tab-content_tab-item.active {
  display: block; }

@media (max-width: 1080px) {
  .good-detail__tab-text {
    width: auto; } }

@media (max-width: 850px) {
  .good-detail__tab {
    padding-top: 36px; }
  .good-detail__tab-content_table-title,
  .good-detail__tab-content_table-text {
    width: 175px; } }

@media (max-width: 576px) {
  .good-detail__tab-link {
    width: fit-content; }
  .good-detail__tab-text {
    font-size: 14px; }
  .good-detail__tab-link {
    font-size: 14px; }
  .good-detail__tab-content_table-item:first-child {
    display: none; }
  .good-detail__tab-content_table-item {
    flex-direction: column; }
  .good-detail__tab-content_table-text:first-child {
    font-weight: 600;
    padding-bottom: 16px; }
  .good-detail__tab-content_table-text:nth-child(3) {
    padding-top: 16px;
    padding-bottom: 10px; }
  .good-detail__tab-content_table-text .good-stats__status {
    margin-bottom: 0; }
  .good-detail__tab-content_table-text {
    font-style: 14px; }
  .good-detail__tab-content_tabs {
    margin-top: 0; }
  .good-detail__tab-content_item:not(:first-child) {
    margin-top: 20px; } }

@media (max-width: 576px) {
  .good-detail__tab-links {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; }
  .good-detail__tab-links::-webkit-scrollbar {
    width: 0;
    height: 0; }
  .good-detail__tab-link {
    flex-shrink: 0;
    margin-right: 20px; } }

#map-shops {
  margin-top: 20px; }

.prices-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  min-height: 20px; }
  @media (max-width: 576px) {
    .prices-wrapper {
      flex-direction: column; } }

.old-price {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #7f818c;
  text-decoration: line-through; }

.old-price__none {
  visibility: hidden; }

.pre-header img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.header {
  background-color: #fff; }
  .header .container {
    position: relative; }
  .header-main {
    position: absolute;
    background-color: #fff;
    top: 59px;
    width: 100%;
    z-index: 5; }
    @media (max-width: 1050px) {
      .header-main {
        left: 16px;
        width: calc(100% - 32px); } }
    @media (min-width: 1101px) {
      .header-main.fixed {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 200;
        width: var(--container);
        max-width: 100%; } }
    .header-main.fixed.add-top-position {
      top: 59px; }
    .header-main.fixed #catalog {
      top: 100%; }
    .header-main.fixed.active-fix {
      margin-left: -8px; }
    @media (max-width: 1400px) {
      .header-main {
        padding-right: 32px;
        border-bottom: none; } }
    @media (max-width: 750px) {
      .header-main {
        position: static;
        padding-right: 0; } }

.header-top {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
  transition: none; }

@media (min-width: 1101px) {
  .header-fixed {
    position: fixed;
    top: 0;
    transition: top 200ms ease;
    left: 50%;
    transform: translateX(-50%);
    z-index: 201;
    width: var(--container);
    max-width: 100%;
    background: white; } }

.header-fixed #catalog {
  top: 100%; }

.header-fixed.active-fix {
  margin-left: -8px; }

.header-logo {
  flex-shrink: 0; }
  .header-logo img {
    width: 147px; }

.header-geo {
  display: flex;
  align-items: center;
  margin-right: 82px; }

.header-geo__img {
  margin-right: 5px; }

.header-geo__text {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #7F818C;
  cursor: pointer; }

.header-geo__text:hover {
  color: #DBA300; }

.header-phone {
  position: relative;
  z-index: 20; }

.header-phone__popup {
  position: absolute;
  background: #fff;
  box-shadow: 0px 7px 7px rgba(133, 133, 133, 0.12);
  z-index: 2;
  width: 300px;
  left: -20px;
  display: none;
  margin-top: 3px; }

.header-phone__popup.active-phone {
  display: block; }

.phones-number__wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 12px; }

.header-phone__popup-wrapper {
  padding: 20px; }
  .header-phone__popup-wrapper .header-phone__after-number_text {
    font-size: 14px; }

.header-phone__work-time {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  margin-top: 4px;
  padding-bottom: 12px;
  border-bottom: 1px solid #F5F5F5; }

.header-phone__link {
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #DBA300;
  padding-top: 12px;
  display: block; }

.header-phone__number {
  display: flex;
  align-items: center;
  cursor: pointer; }

.header-phone__number svg {
  transition: 0.35s; }

@media (min-width: 1350px) {
  .header-phone__number_text:hover {
    color: #DBA300; } }

.header-phone__number.active-phone .header-phone__number_text {
  color: #DBA300; }

.header-phone__number.active-phone svg path {
  fill: #DBA300; }

.header-phone__number.active-phone svg {
  transform: rotate(180deg);
  transition: 0.35s; }

@media (min-width: 991px) {
  .header-phone__number:hover .header-phone__number_text {
    color: #DBA300; }
  .header-phone__number:hover svg path {
    fill: #DBA300; } }

.header-phone__number_text {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-right: 6px; }

/* .header-phone__number svg {
    margin-bottom: 5px;
} */
.header-phone__after-number_text {
  font-weight: 400;
  font-size: 13px;
  color: #ABABAB; }
  @media (max-width: 576px) {
    .header-phone__after-number_text {
      font-size: 12px; } }

.header-top__navbar {
  display: flex;
  margin-left: auto; }

.header-top__navbar-item {
  padding: 0 13px; }

.header-top__navbar-item:hover .header-top__nav-link {
  color: #DBA300; }

.header-top__navbar-item:hover svg path {
  fill: #DBA300; }

.header-top__nav-link {
  letter-spacing: 0.02em;
  color: #7F818C; }

.header-main {
  display: flex;
  align-items: center;
  padding-top: 26.5px;
  padding-bottom: 26px;
  border-bottom: 1px solid #e9e8ee;
  transition: top 200ms ease; }
  @media (max-width: 576px) {
    .header-main {
      padding-bottom: 14px;
      padding-top: 7px;
      width: 100%; } }

.header-catalog {
  display: flex;
  align-items: center;
  background-color: #2b2c2e;
  padding: 15px 30px;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 40px; }

.header-catalog.active {
  border: 1px solid #2b2c2e;
  background: #fce194;
  border: 1px solid #fce194;
  color: #2b2c2e;
  transition: 0.35s; }

.header-search.active {
  margin-right: 0;
  margin-left: 0; }

.header-catalog.active span {
  border: 1px solid #2b2c2e;
  transform: rotate(45deg);
  transition: 0.35s; }

.header-catalog.active span:last-child {
  border: 1px solid #2b2c2e;
  transform: rotate(-45deg);
  margin-top: -5px;
  transition: 0.35s; }

.header-catalog:hover .header-catalog__span span {
  border: 1px solid #2b2c2e;
  background: #2b2c2e;
  transition: 0.35s; }

.header-catalog:hover {
  background: #fce194;
  border: 1px solid #fce194;
  color: #2b2c2e;
  transition: 0.35s; }

.header-catalog__span {
  flex-shrink: 0;
  margin-right: 6px; }

.header-catalog__span span {
  display: block;
  border: 1px solid #fff;
  width: 14px;
  border-radius: 3px;
  height: 1px;
  background: #fff; }

.header-catalog__span span:first-child {
  margin-bottom: 3px; }

.header-search {
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 15px; }

.header-search:hover .input-search {
  border: 1px solid #DBA300;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.header-search:hover .input-btn {
  border: 1px solid #DBA300;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.header-search:hover .input-search::placeholder {
  color: #DBA300; }

.header-search:hover svg path {
  stroke: #DBA300; }

.header-search:hover svg circle {
  stroke: #DBA300; }

.input-search {
  flex-grow: 1;
  background: #fff;
  border: 1px solid #ABABAB;
  border-radius: 6px;
  padding: 13px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 3px;
  line-height: 1.6; }

.input-btn {
  background: #fff;
  border: 1px solid #ABABAB;
  border-radius: 6px;
  padding: 10.5px 12.5px;
  color: #fff;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-search::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #ABABAB; }

.personal-issues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: -10px; }

.personal-issues__content {
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  user-select: none;
  position: relative; }

.personal-issues__content:hover .personal-area__text {
  color: #DBA300; }

.personal-issues__content:hover .personal-issues__stroke {
  stroke: #DBA300; }

.personal-issues__content:hover .personal-issues__content {
  fill: #DBA300; }

.personal-area__text {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 8px; }

.header-bottom {
  max-width: var(--container);
  min-height: 68px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0 16px;
  position: relative;
  padding-top: 133px;
  transition-property: visibility, opacity;
  transition-duration: 150ms, 150ms;
  transition-behavior: ease, ease;
  visibility: visible;
  opacity: 1; }
  .header-bottom.hide {
    visibility: hidden;
    opacity: 0; }
  @media (max-width: 576px) {
    .header-bottom {
      display: none; } }
  .header-bottom__navbar {
    width: 100%; }

@media (min-width: 900px) {
  .header-bottom__navbar .swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header-bottom__navbar .swiper-wrapper .swiper-slide {
    width: initial; } }

.header-bottom__nav-link {
  font-weight: 500;
  font-size: 16px; }

.small-catalog__link {
  font-size: 14px;
  font-weight: 600; }

.header-bottom__navbar-item .header-bottom__nav-link {
  transition: 0.35s; }

.header-bottom__navbar-item svg {
  transition: 0.35s;
  cursor: pointer; }

.header-bottom__navbar-item svg path {
  transition: 0.35s; }

.header-bottom__navbar-item:hover .header-bottom__nav-link {
  color: #DBA300;
  transition: 0.35s; }

.header-bottom__navbar-item:hover svg path {
  fill: #DBA300;
  transition: 0.35s; }

.header-bottom__navbar-item.active .header-bottom__nav-link {
  color: #DBA300;
  transition: 0.35s; }

.header-bottom__navbar-item.active svg {
  transition: 0.35s;
  transform: rotate(180deg);
  cursor: pointer; }

.header-bottom__navbar-item.active svg path {
  fill: #DBA300; }

.header-mob__menu {
  display: none;
  padding: 10px 20px; }
  @media (min-width: 760px) and (max-width: 1049px) {
    .header-mob__menu {
      padding: 13px 20px; } }
  @media (max-width: 576px) {
    .header-mob__menu {
      padding: 9.5px 25px; } }

.personal-area:first-child {
  display: none; }

.header-search.active {
  display: flex; }

.header-company {
  background: #fff;
  position: absolute;
  padding: 20px;
  z-index: 300;
  width: 190px;
  margin-left: -20px;
  margin-top: 3px;
  box-shadow: 0px 7px 5px rgba(133, 133, 133, 0.12);
  display: none; }

.header-company.active {
  display: block; }

.header-company__navbar {
  display: flex;
  flex-direction: column; }

.header-company__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7F818C;
  padding: 12px 0;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header-company__link:last-child {
  border-bottom: none;
  padding-bottom: 0; }

.header-company__link:hover {
  color: #DBA300; }

.header-company__link:hover::after {
  content: '';
  display: flex;
  height: 10px;
  width: 30px;
  background: url(../images/arrow-menu.svg) no-repeat center center;
  margin-right: -10px; }

.header-company__item {
  transition: 0.35s;
  cursor: pointer; }
  .header-company__item svg {
    transition: 0.35s; }

.header-company__item.active .header-top__nav-link {
  color: #DBA300; }

.header-company__item.active svg path {
  transition: 0.35s;
  fill: #DBA300; }

.header-company__item.active svg {
  transform: rotate(180deg);
  transition: 0.35s;
  cursor: pointer; }

.cart.personal-issues__content {
  position: relative; }

.cart.active-cart .personal-area__text {
  color: #DBA300; }

.cart.active-cart svg path {
  fill: #DBA300; }

.small-catalog {
  width: 350px;
  background: #fff;
  box-shadow: 0px 7px 8px rgba(133, 133, 133, 0.12);
  padding: 20px;
  position: absolute;
  right: 0;
  top: 155px;
  z-index: 3;
  display: none; }

.small-catalog.active-small {
  display: block; }

.hedaer-bottom__nav-link_more svg {
  transition: 0.35s;
  cursor: pointer; }

.hedaer-bottom__nav-link_more.active a {
  color: #DBA300; }

.hedaer-bottom__nav-link_more.active svg {
  transform: rotate(180deg);
  transition: 0.35s;
  cursor: pointer; }

.hedaer-bottom__nav-link_more.active svg path {
  fill: #DBA300; }

.small-catalog__item {
  padding: 12px 0;
  border-bottom: 1px solid #e9e8ee; }

.small-catalog__item:last-child {
  border-bottom: none;
  padding-bottom: 0; }

.hedaer-bottom__nav-link:hover {
  color: #DBA300; }

@media (max-width: 1400px) {
  .header-bottom {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px; } }

@media (max-width: 1300px) {
  .header-main {
    flex-wrap: wrap; }
  .header-logo {
    margin-bottom: 15px; } }

@media (max-width: 1091px) {
  .personal-area__text.hidden {
    display: none; } }

@media (max-width: 1050px) {
  .header-top__navbar {
    display: none; }
  .header-logo {
    order: -1; }
  .header-mob__menu {
    order: -1; }
  .personal-area__text.hidden {
    display: none; }
  .personal-area__text {
    color: #fff;
    position: absolute;
    width: 0;
    right: 0; }
  .personal-issues__content:hover .personal-area__text {
    color: #fff; }
  .cart.active .personal-area__text {
    color: #fff; }
  .personal-area__text span {
    position: absolute;
    color: #2b2c2e;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 17px;
    padding: 4px;
    background: #fce194;
    border-radius: 4px;
    font-size: 12px; }
  .header-catalog {
    display: none; }
  .header-mob__menu {
    display: flex; }
  .header-phone {
    margin-left: auto;
    z-index: 500; }
  .header-phone__popup {
    left: -115px; } }

@media (max-width: 900px) {
  .header-bottom__navbar-item {
    width: initial; }
  .header-main {
    border: none; }
  .cart-popup {
    right: -198%; }
  /* .header-bottom {
      background: #f9f9f9;
  } */ }

@media (max-width: 750px) {
  .header-search {
    display: none; }
  .header-main {
    justify-content: normal; }
  .header-catalog {
    margin-left: 0;
    order: 0; }
  .personal-area:first-child {
    display: flex; }
  .header-top {
    padding-top: 0; }
  .header-geo {
    display: none; }
  .header-logo {
    flex-basis: 100%;
    margin-bottom: 12px; }
  #header .container {
    position: relative; }
  .header-phone {
    position: absolute;
    right: 16px;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .personal-issues {
    margin-left: auto; }
  .header-search {
    order: 3;
    width: 100%;
    margin-top: 20px; }
  .input-search {
    width: 100%; } }

@media (max-width: 576px) {
  .pre-header {
    height: 36px; }
  .header-phone__popup {
    left: -98%;
    top: 20px;
    width: 328px; }
  .personal-issues__content {
    padding: 0 12px;
    position: relative; }
  /* .personal-issues__content:last-child {
    padding-right: 0;
  } */
  .header-phone__number_text {
    font-size: 15px; } }

@media (max-width: 460px) {
  .header-logo img {
    width: 109px; } }

@media (max-width: 380px) {
  .small-catalog {
    width: 270px; } }

@media (max-width: 340px) {
  .logo-link img {
    width: 35%; }
  .header-phone {
    top: 22px; }
  .header-phone__popup {
    left: -68%; } }

.header-company__item svg {
  transition: 0.35s;
  cursor: pointer; }

.small-catalog__link:hover {
  color: #DBA300; }

@media (max-width: 576px) {
  .header {
    box-shadow: 0px 7px 40px rgba(133, 133, 133, 0.12); } }

#hits {
  padding-top: 120px; }

.hits {
  position: relative; }

@media (min-width: 1201px) {
  .goods-slider {
    padding: 40px 20px; } }

.goods-img {
  position: relative; }
  @media (min-width: 1300px) {
    .goods-img {
      height: 275px;
      display: flex; } }
  @media (min-width: 1490px) {
    .goods-img {
      height: 325px; } }
  @media (max-width: 1025px) {
    .goods-img {
      height: 230px; } }
  @media (max-width: 576px) {
    .goods-img {
      height: 212px; } }
  @media (max-width: 380px) {
    .goods-img {
      height: 165px; } }
  .goods-img a {
    display: block; }
  .goods-img img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.goods-icon {
  position: absolute;
  top: 14px;
  width: 100%;
  padding: 0 14px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 576px) {
    .goods-icon {
      top: 8px;
      padding-right: 8px;
      padding-left: 8px; } }

.goods-icon__left-item {
  display: flex;
  flex-wrap: wrap; }
  .goods-icon__left-item span {
    margin-right: 6px;
    margin-bottom: 4px; }

.goods-slide__content {
  padding: 16px; }

.goods-slide__note {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #7f818c;
  margin-bottom: 10px;
  user-select: none; }

.goods-slide__note span {
  text-transform: uppercase; }

.goods-slide__name {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2b2c2e;
  margin-bottom: 24px;
  transition: .3s color;
  user-select: none;
  height: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3; }
  @media (max-width: 576px) {
    .goods-slide__name {
      height: 50px; } }
  @media (any-hover: hover) {
    .goods-slide__name:hover {
      color: #DBA300; } }

.goods-slide__price {
  font-weight: 700;
  font-size: 18px; }

.goods-btn {
  width: 100%;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: .3s all;
  user-select: none; }
  @media (max-width: 992px) {
    .goods-btn span {
      display: none; } }

.goods-slide {
  border-radius: 10px; }
  .goods-slide.favorite .favorite-btn svg {
    fill: #F95E5E; }
    .goods-slide.favorite .favorite-btn svg path {
      stroke: #F95E5E; }

.goods-slide:hover {
  box-shadow: 0px 7px 25px rgba(133, 133, 133, 0.12); }

.goods-slide:hover .goods-btn {
  opacity: 1;
  visibility: visible;
  pointer-events: all; }

.goods-icon__right-item {
  cursor: pointer;
  width: 32px;
  height: 32px; }

.goods-icon__right-item:hover svg path {
  stroke: #F16363; }

.arrow-goods {
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  border: 1px solid #7F818C;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  transition: .3s border-color; }

@media (any-hover: hover) {
  .arrow-goods:hover {
    border-color: #DBA300; } }

@media (any-hover: hover) {
  .arrow-goods:hover svg path {
    fill: #DBA300; } }

.arrow-goods__prev {
  left: -10%; }

.arrow-goods__next {
  right: -10%; }

@media (max-width: 1650px) {
  .arrow-goods__prev {
    left: -5%; }
  .arrow-goods__next {
    right: -5%; } }

@media (max-width: 1500px) {
  .arrow-goods__prev {
    left: 0; }
  .arrow-goods__next {
    right: 0; }
  .goods-slider {
    width: 90%; } }

@media (max-width: 1300px) {
  .arrow-goods__prev {
    left: auto;
    right: 6%; }
  .arrow-goods {
    height: 36px;
    width: 36px; }
  .arrow-goods__next {
    right: 0; }
  .arrow-goods {
    top: 0; }
  .goods-slider {
    width: 100%; }
  .goods-slide .goods-btn {
    visibility: visible;
    opacity: 1;
    pointer-events: all; } }

@media (max-width: 850px) {
  .goods-slide__note {
    height: 18px;
    overflow: hidden; } }

@media (max-width: 576px) {
  .goods-slide__name {
    font-size: 12px; }
  .goods-slide__price {
    font-size: 14px; }
  .goods-slide__price-old {
    font-size: 12px !important; }
  .goods-btn {
    font-size: 14px;
    padding: 12px; }
  .arrow-goods__prev {
    left: auto;
    right: 12%; }
  .goods-slide__note {
    font-size: 11px;
    height: 45px;
    overflow: hidden; }
  .goods-slide__price-wrapper {
    flex-direction: column;
    align-items: flex-start !important; }
  .goods-slide__price {
    order: 1;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%; }
  .goods-slide__price-old {
    margin-bottom: 0 !important; } }

@media (max-width: 450px) {
  #special .goods-icon__left-item:last-child {
    order: -1; }
  .goods-icon__left-item {
    margin-bottom: 0; }
  .goods-icon__left-item .sale {
    padding: 4px;
    font-size: 11px; }
  .goods-icon__right-item svg {
    width: 29px;
    margin-top: -3px; }
  .goods-icon__left-item svg {
    width: 77%;
    margin-top: -3px; } }

@media (max-width: 380px) {
  .arrow-goods__prev {
    right: 13%; } }

@media (max-width: 350px) {
  .arrow-goods__prev {
    left: auto;
    right: 14%; } }

@media (max-width: 576px) {
  .goods-slide__content {
    padding: 10px 8px; }
  .goods-slide__note {
    margin-bottom: 6px; }
  .goods-slide__name {
    height: auto; }
  .prices-wrapper {
    margin-bottom: 0;
    height: 55px; } }

@media (max-width: 576px) {
  .goods-slide__content {
    padding: 10px 8px; }
  .goods-slide__note {
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    height: 14px; }
  .goods-slide__name {
    height: 48px; }
  .prices-wrapper {
    margin-bottom: 0;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; } }

@media (max-width: 991px) and (min-width: 768px) {
  .goods-img a {
    display: inline; } }

#interesting-block {
  margin-top: 120px; }
  #interesting-block .news-page__item-title {
    margin-top: 20px !important; }
  #interesting-block .interesting-slider {
    margin-top: 40px; }
  #interesting-block .news-page__item-img {
    position: relative; }
  #interesting-block .hide {
    display: none; }

.interesting-slide__tag {
  position: absolute;
  padding: 8px 12px;
  background: #2b2c2e;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  text-transform: uppercase;
  color: #fff;
  bottom: 16px;
  left: 16px;
  height: 34px; }

@media (max-width: 1400px) {
  .interesting-slider {
    padding-left: 16px; } }

@media (max-width: 576px) {
  #interesting-block {
    padding-left: 20px;
    margin-top: 54px; }
    #interesting-block .title {
      padding-left: 0 !important; }
  #interesting-block .interesting-slider {
    margin-top: 30px;
    padding-left: 0; } }

.main-banners {
  padding-top: 6px; }

.main-banners {
  display: flex; }

.main-banners__item {
  max-width: 900px;
  position: relative; }

.main-banners__item:last-child {
  margin-bottom: 20px; }

@media (min-width: 1300px) {
  .main-banners__item-img:first-child {
    margin-bottom: 20px; } }

.main-banners__item:last-child {
  margin-left: 20px; }

.slider-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  bottom: 52px;
  z-index: 2;
  padding: 0 50px; }

.slider-bottom__btn .slider-btn {
  display: none; }

.slider-btn {
  background: #2b2c2e;
  padding: 14px 40px;
  border-radius: 6px; }

.slider-btn:hover {
  background: #fce194;
  border: 1px solid #fce194;
  color: #2b2c2e; }

.arrow-block {
  display: flex;
  align-items: center; }

.arrow {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s background-color; }
  @media (any-hover: hover) {
    .arrow:hover {
      background-color: #FCE194; } }

.arrow:first-child {
  margin-right: 16px; }

.main-banners__item-img {
  display: block;
  transition: 0.35s; }

.main-banners__item-img img {
  border-radius: 6px; }

.main-banners__slide img {
  border-radius: 6px; }

.mobile {
  display: none; }

@media (max-width: 1300px) {
  .main-banners {
    flex-direction: column; }
  .main-banners__item:last-child {
    display: flex;
    margin-top: 8px;
    margin-left: 0; }
  .main-banners__item-img {
    width: 50%; }
  .main-banners__item-img:first-child {
    margin-right: 15px; } }

@media (min-width: 991px) {
  .main-banners__item-img:hover {
    transform: scale(1.02);
    transition: 0.35s; } }

@media (max-width: 576px) {
  .slider-bottom {
    padding: 0 20px;
    bottom: 20px;
    display: none; }
  .main-banners__item-img {
    width: 100%; }
  .main-banners__item-img img {
    width: 100%;
    height: 100%;
    border-radius: 6px; }
  .main-banners__item-img:first-child {
    margin-right: 8px; }
  .desk {
    display: none; }
  .mobile {
    display: block; } }

@media (max-width: 340px) {
  .slider-btn {
    padding: 15px; } }

#map-block {
  padding-top: 120px; }

.map-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; }

.after-title {
  max-width: 670px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7F818C;
  text-align: justify; }

.map {
  height: 500px;
  width: 100%;
  margin-top: 42px; }
  @media (max-width: 576px) {
    .map {
      height: 360px; } }
  .map > * {
    height: 100% !important; }

#map-block .map-balloon__btn,
#map-block .status,
.shops-page__map .map-balloon__btn,
.shops-page__map .status {
  display: none; }

.shops-page .map-balloon__btn,
.shops-page .status,
.shops-page__map .map-balloon__btn,
.shops-page__map .status {
  display: none; }

#map-block .map-balloon__btn,
.good-detail__tab .map-balloon__btn {
  display: none; }

#map-block .status,
.good-detail__tab .status {
  font-size: 14px; }

#map-block
#map .map-balloon__btn,
#map-block
#map .status {
  display: none; }

.map__burger-img {
  float: right;
  height: 100px;
  width: 100px; }

@media (max-width: 1010px) {
  .map-block .after-title {
    margin-top: 20px; } }

#map-block .title {
  margin-top: -9px; }

@media (max-width: 576px) {
  #map-block .after-title {
    margin-bottom: 16px;
    margin-top: 0; }
  #map {
    margin-top: 0; } }

#navigator {
  padding-top: 100px; }

.navigator-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.navigator-head__text {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #7f818c; }

.navigator-head__tabs {
  display: flex;
  align-items: center; }

.navigator-head__tab {
  width: 160px;
  padding: 15px;
  margin-left: 10px; }

.navigator-head__tab.active {
  background: #2b2c2e;
  color: #fff; }

.navigator-content {
  margin-top: 40px; }

.navigator-content__item {
  display: none; }

.navigator-content__item.active {
  display: flex; }

.human-part__wrapper.active .human-part {
  opacity: 0.6;
  transition: 0.4s; }

.human-part__wrapper.active-tab .human-part {
  opacity: 0.6;
  transition: 0.4s; }

.person-title__wrapper {
  cursor: pointer; }

.navigator-content__first-list_item {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #7f818c;
  padding: 18px 0;
  border-top: 1px solid #e9e8ee;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit; }

@media (min-width: 700px) {
  .navigator-content__first-list_item:hover {
    color: #DBA300; }
  .navigator-content__second-list_text:hover {
    color: #DBA300; } }

.navigator-content__first-list_item.active-tab {
  color: #DBA300; }

@media screen and (min-width: 701px) {
  .navigator-content__first-list_item.active-tab {
    background: #fce194;
    padding-right: 10px;
    padding-left: 10px;
    color: #2B2C2E;
    border-radius: 10px; }
  .navigator-content__first-list_item.active-tab + .navigator-content__first-list_item {
    border-top-color: transparent; } }

.navigator-content__first-list_item:first-of-type {
  border-top: none; }

.navigator-content__first-list_item:last-of-type {
  border-bottom: 1px solid #e9e8ee; }

.navigator-content__first-list {
  display: flex;
  flex-direction: column;
  width: 315px;
  margin-right: 60px; }

.person-title__wrapper.active-tab g path {
  fill: #fce194 !important; }

.person-title__wrapper.active-tab rect {
  fill: #fce194 !important; }

.navigator-content__first-list_item.active-tab::after {
  content: '';
  display: flex;
  height: 10px;
  width: 30px;
  background: url("../images/arrow-gold.svg") no-repeat center center;
  margin-right: 3px; }

.human-part {
  opacity: 0; }

.navigator-content__second-list_item {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 270px; }

.navigator-content__second-list_item.active-tab {
  display: flex;
  flex-direction: column; }

.navigator-content__second-list_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  padding: 18px 0; }

.navigator-img {
  position: absolute; }

.navigator-content__picture {
  position: relative; }

.body-img {
  position: absolute;
  top: 227px;
  left: 0;
  opacity: 0;
  transition: 0.1s; }

.body-img2 {
  top: 252px;
  left: 142px; }

.body-img.active-tab {
  opacity: 1;
  transition: 0.1s; }

.body-img.active {
  opacity: 1;
  transition: 0.1s; }

.navigator-content__picture svg {
  position: relative; }

.navigator-content__picture {
  margin-right: 30px; }

.navigator-content__first-list_wrapper {
  position: relative;
  width: 100%; }

.navigator-content__first-list_item > .navigator-content__first-list_item-text-wrapp svg {
  display: none; }

@media (max-width: 1355px) {
  .navigator-content__item {
    flex-direction: column; }
  .navigator-content__second-list_item {
    display: none;
    position: absolute;
    right: initial;
    left: 50%;
    top: 10%;
    width: 270px; }
  .navigator-content__first-list {
    margin-top: 30px; }
  .navigator-content__picture {
    text-align: center; } }

@media (max-width: 1150px) {
  .navigator-head {
    flex-wrap: wrap;
    margin-top: 20px; }
  .navigator-head__tab {
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px; }
  .navigator-content__item {
    flex-wrap: wrap; } }

@media (max-width: 765px) {
  .navigator-content__second-list_item {
    left: 55%; } }

@media (max-width: 700px) {
  .navigator-content__picture {
    display: none; }
  .navigator-head__tabs {
    flex-wrap: wrap; }
  .navigator-content__first-list {
    margin-right: 20px; }
  .navigator-content__first-list {
    width: 100%; }
  .navigator-content__second-list_wrapper {
    max-width: 200px; }
  .navigator-content__item {
    flex-wrap: nowrap; }
  .navigator-content__second-list_item {
    position: static; }
  .navigator-content__first-list_item {
    flex-direction: column;
    align-items: baseline; }
  .navigator-content__first-list_item.active-tab::after {
    display: none; }
  .navigator-content__first-list_item-text-wrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .navigator-content__first-list {
    margin-top: 0; }
  .navigator-content {
    margin-top: 16px; }
  .navigator-content__first-list_item > .navigator-content__first-list_item-text-wrapp svg {
    display: flex; }
  .navigator-content__first-list_item.active-tab > .navigator-content__first-list_item-text-wrapp svg path {
    fill: #DBA300; }
  .navigator-content__first-list_item.active-tab > .navigator-content__first-list_item-text-wrapp svg {
    transform: rotate(180deg);
    transition: 0.35s; } }

@media (max-width: 576px) {
  .navigator-content__first-list_item.active-tab::after {
    width: 70px; }
  .navigator-head__tabs {
    justify-content: space-around;
    margin-top: 6px; }
  .navigator-head__tab {
    max-width: 190px;
    width: 100%;
    margin-right: 0; } }

@media (max-width: 440px) {
  .navigator-head__tab {
    max-width: 170px; } }

@media (max-width: 396px) {
  .navigator-head__tab {
    mxa-width: 155px; } }

@media (max-width: 365px) {
  .navigator-head__tab {
    width: 145px; }
  .navigator-head__tab {
    font-size: 12px;
    padding: 13px; } }

@media (max-width: 350px) {
  .navigator-head__tab {
    width: 125px; } }

#navigator .title {
  margin-bottom: 30px; }

@media (max-width: 576px) {
  #navigator .title {
    margin-bottom: 20px; } }

.navigator-hide {
  display: none; }

.human-part_vein {
  opacity: 1;
  stroke: #000; }

.human-part__wrapper.active-tab .human-part.human-part_vein {
  opacity: 1;
  stroke: #F0C044; }

.human-part__wrapper.active-tab .human-part.human-part_vein-adult {
  opacity: 1; }

.person-title__wrapper.active-tab {
  z-index: 999999; }

#new-goods {
  padding-top: 90px; }

.news-detail-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 970px;
  margin-top: 20px; }
  .news-detail-info .news-page__item-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #7f818c;
    text-transform: initial; }

.news-detail-info__social-links {
  display: flex; }
  .news-detail-info__social-links a {
    margin-left: 16px; }

#news-page-detail .news-page__head-item:last-child {
  width: initial; }

#news-page-detail .news-page__head-item:last-child a:first-child {
  display: flex; }

#news-page-detail .news-page__head-item_title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  padding-bottom: 30px;
  border-bottom: 1px solid #e9e8ee; }

#news-page-detail .news-page__item-title {
  margin-top: 0; }

#news-page-detail .news-page__head-block-item {
  margin-bottom: 0;
  padding: 30px 0;
  border-bottom: 1px solid #e9e8ee; }

#news-page-detail .news-page__head {
  margin-top: 24px;
  padding-bottom: 50px;
  border-bottom: none; }
  #news-page-detail .news-page__head .news-page__head-item:first-child {
    width: 100%;
    max-width: 970px; }
    #news-page-detail .news-page__head .news-page__head-item:first-child img {
      width: 100%;
      height: 100%; }

#news-page-detail .news-page-content {
  width: 940px; }

#news-page-detail .news-page-content ol, #news-page-detail .news-page-content ul {
  margin-left: 15px; }

#news-page-detail .news-detail-info .news-page__item-date {
  margin-top: 0; }

#news-page-detail .news-page-content__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7f818c;
  margin-top: 30px;
  margin-bottom: 50px; }
  #news-page-detail .news-page-content__text ul {
    list-style: inside;
    margin-left: 7px; }
    #news-page-detail .news-page-content__text ul li {
      margin-bottom: 10px; }

#news-page-detail .news-page-content__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e; }

#news-page-detail .news-page-content__title_small {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2b2c2e;
  margin-top: 50px; }

#news-page-detail .news-page__head-item_mobile {
  display: none;
  padding: 0 16px; }

@media (max-width: 1400px) {
  #news-page-detail .news-page__head-item:first-child {
    display: block; }
  #news-page-detail .news-page-content {
    padding: 0 16px; }
  #news-page-detail .news-page__head {
    flex-wrap: nowrap;
    padding-left: 16px; }
  #news-page-detail .container {
    padding: 0; }
  #news-page-detail .title {
    padding: 0 16px; }
  #news-page-detail .news-detail-info {
    padding: 0 16px; }
  #news-page-detail .news-page__head-item:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 20px; }
  #news-page-detail .news-page__head-block-item {
    padding: 10px 0; }
  #news-page-detail .news-page__head-item_title {
    padding-bottom: 10px; }
  #news-page-detail .news-page__head-item:last-child {
    gap: 10px 20px; } }

@media (max-width: 1400px) {
  #news-page-detail .news-page__head-item:last-child {
    display: none; }
  #news-page-detail .news-page-content img {
    padding-left: 16px; }
  #news-page-detail .news-detail-info {
    width: 100%; }
  #news-page-detail .news-page-content {
    width: 100%; }
  #news-page-detail .news-page__head-item_mobile {
    display: block !important;
    margin-left: 0 !important; }
  #news-page-detail .news-page-content__text {
    padding: 0 16px; }
  #news-page-detail .news-page-content__title {
    padding: 0 16px; }
  #news-page-detail .news-page-content__title_small {
    padding: 0 16px; } }

@media (max-width: 576px) {
  #news-page-detail .title {
    width: 310px; }
  #news-page-detail .news-page-content img {
    padding-left: 0; }
  #news-page-detail .news-page__head {
    margin-top: 20px;
    padding-left: 0; }
  #news-page-detail .news-page-content__text {
    margin-top: 20px;
    margin-bottom: 40px; }
  #news-page-detail .news-page-content__title {
    font-size: 16px; }
  #news-page-detail .news-page-content__title_small {
    font-size: 16px;
    margin-top: 30px;
    text-transform: lowercase; }
  #news-page-detail .news-page__head {
    padding-bottom: 40px; }
  #news-page-detail .news-page__head-item_title {
    font-size: 24px;
    padding-bottom: 30px;
    margin-top: 24px; }
  #news-page-detail .news-page__item-date {
    margin-top: 10px; }
  #news-page-detail .news-page__head-block-item {
    padding: 20px 0; }
  #news-page-detail .news-detail-info__social-links a {
    margin-left: 10px; } }

@media (min-width: 991px) {
  #news-page-detail .news-page__item-img_wrapper {
    height: 570px; } }

@media (min-width: 576px) {
  #news-page-detail .news-page__head-block-item:first-child {
    width: 325px; } }

.dop-page-title {
  margin-top: 50px; }

#news-page .row-3 {
  gap: 40px 100px;
  margin-top: 50px; }

.news-page {
  margin-top: 50px; }

.news-page__item-title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2; }

.news-page__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #7f818c;
  margin-top: 16px;
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 8; }

@media (max-width: 576px) {
  #news-page .row-3 {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
    gap: 30px 100px; }
  .dop-page-title {
    margin-top: 15px; }
  .news-page__item-title, .news-page__item-text {
    height: auto; } }

@media (min-width: 991px) {
  .news-page__item:hover {
    cursor: pointer; }
    .news-page__item:hover .news-page__item-title {
      color: #DBA300; } }

.news-page__item-date {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ababab;
  margin-top: 16px; }

.news-page__head-item:last-child {
  width: 555px; }

#news-page .news-page__item-img_wrapper {
  width: 785px;
  height: 460px; }

#news-page .news-page__item-img_wrapper a img {
  width: 100%;
  height: 100%; }

.news-page__head-block-item {
  width: 325px;
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .news-page__head-block-item:first-child {
      width: 216px; } }

@media (min-width: 576px) {
  .news-page__head-block-item img {
    height: 140px;
    border-radius: 6px;
    width: 100%; } }

.news-page__head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.news-page__head-block {
  display: flex;
  justify-content: space-between; }

.news-page__head {
  margin-top: 50px; }

#news-page .pagination ul {
  justify-content: center;
  margin-top: 50px; }

.news-page__item-img {
  width: 100%;
  height: 0;
  padding-bottom: 64.7%;
  position: relative; }

.news-page__item-img img {
  height: 100%;
  border-radius: 6px;
  width: 100%;
  width: 100%;
  position: absolute; }

@media (min-width: 991px) {
  .news-page__head-block:hover .news-page__item-title {
    color: #DBA300; } }

.news-page__head {
  padding-bottom: 30px;
  border-bottom: 1px solid #E9E8EE; }

.news-page__head-item:last-child a:first-child {
  display: none; }

@media (max-width: 1400px) {
  .news-page__head-item:first-child {
    display: none; }
  .news-page__head-item:last-child a:first-child {
    display: block; }
  .news-page__head-item:last-child {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 20px; }
  .news-page__head-block {
    display: block; }
  .news-page__head-block-item {
    width: 100%; }
  #news-page .row-4 {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 930px) {
  #news-page .row-4 {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 8px; }
  .news-page__head-item:last-child {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 576px) {
  #news-page .row-4 {
    display: flex;
    flex-direction: column; }
  .news-page__head-item:last-child {
    display: flex;
    flex-direction: column; }
  .news-page__head-block-item img {
    width: 100%;
    border-radius: 6px; }
  .news-page__head {
    margin-top: 30px; }
  .news-page__head-block-item:first-child img {
    height: 100%;
    border-radius: 6px;
    width: 100%;
    width: 100%;
    position: absolute; }
  .news-page__head-block-item:first-child {
    margin-bottom: 0;
    width: 100%;
    padding-bottom: 64.7%;
    position: relative; }
  .news-page__head {
    padding-bottom: 40px; }
  .news-page {
    margin-top: 40px; }
  .news-page__item-img img {
    width: 100%; }
  .news-page__item-title {
    margin-top: 20px; } }

.not-found-page {
  padding-top: 50px; }
  @media (max-width: 576px) {
    .not-found-page {
      padding: 40px 0; } }
  .not-found-page__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .not-found-page__inner > *:not(:last-child) {
      margin-bottom: 30px; }
  .not-found-page__img {
    width: 290px;
    height: 205px; }
    @media (max-width: 576px) {
      .not-found-page__img {
        width: 170px;
        height: 120px; } }
  .not-found-page__text {
    max-width: 555px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #7F818C; }
    @media (max-width: 576px) {
      .not-found-page__text {
        font-size: 14px;
        line-height: 130%;
        max-width: 260px; } }
    .not-found-page__text:not(:last-child) {
      margin-bottom: 20px; }
  .not-found-page__btn {
    padding: 14px 47px; }

.one-click__popup, .common__popup {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: 15vh 0; }

.one-click__popup.active, .common__popup.active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s; }

.one-click__popup-wrapper {
  padding: 40px;
  background: #fff;
  max-width: 520px; }
  @media (max-width: 575px) {
    .one-click__popup-wrapper {
      height: 100%;
      overflow: auto; } }
  .one-click__popup-wrapper .error {
    color: #f95e5e; }

.popup-head {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: -20px; }

.popup-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-bottom: 20px;
  margin-top: -15px; }

.popup-aftertitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #7F818C;
  margin-bottom: 34px; }

.popup-form {
  margin-top: 34px;
  display: flex;
  flex-direction: column; }

.popup-form label {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column; }

.popup-input {
  padding: 12px 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e;
  background: #F5F5F5;
  border-radius: 6px;
  margin-top: 8px;
  border: none; }

.popup-input::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #2b2c2e; }

.checkbox-text a {
  color: #DBA300;
  text-decoration: underline; }

.popup-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  padding: 14px;
  background: #2b2c2e; }

.popup-btn:hover {
  background: #fce194;
  border: 1px solid #fce194;
  color: #2b2c2e; }

@media (max-width: 576px) {
  .popup-cross {
    margin-right: 7px; } }

@media (max-width: 576px) {
  .one-click__popup.active, .common__popup.active {
    padding: 12px; }
  .popup-title {
    font-size: 18px; }
  .popup-aftertitle {
    font-size: 14px; }
  .one-click__popup-wrapper {
    padding: 20px; } }

.input-wrap.error,
.input-wrap.success {
  margin-bottom: 20px !important; }

.order-good {
  display: flex;
  align-items: center; }
  .order-good__img {
    flex-shrink: 0;
    margin-right: 20px;
    width: 60px;
    height: 60px; }
    @media (max-width: 767px) {
      .order-good__img {
        width: 100px;
        height: 100px; } }
    .order-good__img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .order-good__content {
    flex-grow: 1; }
    @media (min-width: 768px) {
      .order-good__content {
        display: flex;
        justify-content: space-between; } }
  .order-good__descr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%; }
    .order-good__descr > *:not(:last-child) {
      margin-bottom: 6px; }
      @media (max-width: 767px) {
        .order-good__descr > *:not(:last-child) {
          margin-bottom: 8px;
          width: 100%; } }
  .order-good__title {
    font-weight: 500;
    font-size: 16px; }
    @media (max-width: 767px) {
      .order-good__title:not(:last-child) {
        margin-bottom: 17px; } }
    @media (max-width: 576px) {
      .order-good__title {
        font-size: 14px;
        line-height: 130%;
        margin-bottom: 17px; } }
  @media (max-width: 576px) {
    .order-good .prices {
      align-items: center; } }

.order-good__descr a:hover {
  color: #dba300; }

.orders-history {
  width: 670px;
  max-width: 100%; }

.order-item:not(:last-child) {
  border-bottom: 1px solid #E9E8EE;
  padding-bottom: 30px; }

.order-item:not(:first-child) {
  padding-top: 30px; }

.order-item > .btn--underlined {
  display: none; }
  @media (max-width: 767px) {
    .order-item > .btn--underlined {
      display: block;
      margin-top: 14px; } }

@media (min-width: 768px) {
  .order-item__inner {
    margin: -7px -7px 13px;
    display: flex;
    justify-content: space-between; } }

.order-item__left, .order-item__right {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .order-item__left, .order-item__right {
      padding: 7px; } }

.order-item__title {
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 8px; }

.order-item__label {
  padding: 6px 12px;
  margin-bottom: 10px; }

.order-item__date {
  display: flex;
  font-size: 15px;
  color: #7F818C;
  margin-top: 15px; }
  @media (max-width: 767px) {
    .order-item__date {
      margin-bottom: 14px; } }
  .order-item__date time {
    color: #2b2c2e;
    margin-left: 6px; }

.order-item__left {
  align-items: flex-start; }

@media (min-width: 768px) {
  .order-item__right {
    align-items: flex-end; } }

.order-item__right > *:not(:last-child) {
  margin-bottom: 17px; }

@media (max-width: 767px) {
  .order-item__right .btn--underlined {
    display: none; } }

.order-item__sum {
  display: flex;
  align-items: center;
  color: #7F818C;
  text-transform: uppercase; }
  .order-item__sum b {
    margin-left: 6px;
    font-weight: 500;
    font-size: 24px;
    color: #2b2c2e; }

.order-item__toggle-content {
  display: none;
  padding-top: 20px; }

.order-item__good:not(:last-child) {
  margin-bottom: 20px; }

#ortos-tab {
  padding-top: 55px; }

.ortos-tab__wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }

.ortos-tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  position: relative; }

.ortos-tab__text {
  font-size: 16px; }
  .ortos-tab__text a {
    color: #DBA300; }

.ortos-tabs__item {
  width: 800px; }
  @media (max-width: 1350px) {
    .ortos-tabs__item {
      width: 566px; } }
  @media (max-width: 1096px) {
    .ortos-tabs__item {
      width: 366px; } }
  @media (max-width: 576px) {
    .ortos-tabs__item {
      width: 100%; } }

.ortos-tab__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2b2c2e;
  padding: 36px 0;
  border-bottom: 1px solid #e9e8ee;
  cursor: pointer; }

.ortos-tab__link span {
  font-weight: 400;
  font-size: 44px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-right: 10px; }

@media (min-width: 991px) {
  .ortos-tab__link:hover {
    color: #DBA300; }
    .ortos-tab__link:hover span {
      color: #DBA300; } }

.ortos-tab__link.active {
  color: #DBA300; }
  .ortos-tab__link.active span {
    color: #DBA300; }

.ortos-tab__content {
  padding-top: 71px; }

.ortos-tab__btn-wrapper {
  width: 215px;
  margin-top: 50px; }

.ortos-tab__content-item {
  display: none;
  position: absolute;
  right: 0;
  top: 71px;
  width: 555px; }

@media (min-width: 860px) {
  .ortos-tab__content-item.active {
    display: block; } }

.ortos-tab__link-text svg {
  display: none; }

.ortos-tab__link-text {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ortos-tab__link.active > .ortos-tab__link-text > svg {
  transition: 0.35s; }

@media (max-width: 930px) {
  .ortos-tab__content-item {
    width: 350px; }
  .ortos-tabs__item:last-child {
    flex-basis: 49%; } }

@media (max-width: 860px) {
  .ortos-tabs__item:last-child {
    flex-basis: 100%; }
  .ortos-tab__content-item {
    position: static;
    margin-top: 14px;
    display: block;
    height: 0;
    overflow: hidden;
    transition: 1s;
    display: block; }
  .ortos-tab__content-item.active {
    height: auto;
    transition: 1s;
    padding-top: 14px;
    padding-bottom: 20px; } }

@media (max-width: 700px) {
  .ortos-tab__link-text svg {
    display: inline-block; }
  .ortos-tab__text,
  .ortos-tab__content-item {
    width: 100%; }
  .ortos-tab__link.active > .ortos-tab__link-text > svg {
    transform: rotate(180deg);
    transition: 0.35s; }
  .ortos-tab__link.active > .ortos-tab__link-text > svg path {
    fill: #DBA300; } }

@media (max-width: 576px) {
  .ortos-tab__link {
    padding: 16px 0; }
  .ortos-tab__content-item {
    margin-top: 0; }
  .ortos-tab__link span {
    font-size: 24px; }
  .ortos-tab__wrapper {
    flex-wrap: wrap; }
  .ortos-tabs__item {
    flex-basis: 35%; }
  .after-title {
    font-size: 14px; }
  .ortos-tab__btn {
    padding: 12px; }
  .ortos-tab__link-text svg {
    transition: 1s; }
  .ortos-tab__link-text.active {
    color: #DBA300; }
    .ortos-tab__link-text.active span {
      color: #DBA300; }
    .ortos-tab__link-text.active svg {
      transform: rotate(180deg);
      transition: 1s; }
    .ortos-tab__link-text.active svg path {
      fill: #DBA300; } }

@media (max-width: 470px) {
  .after-title {
    font-size: 12px; } }

.ortos-tab__wrapper .title {
  flex: auto; }

.ortos-tab__wrapper .after-title {
  flex: auto;
  letter-spacing: 0.04em; }
  @media (max-width: 370px) {
    .ortos-tab__wrapper .after-title {
      letter-spacing: 0; } }

.ortos-tab__content-item {
  width: 475px;
  top: 33px; }

.ortos-tab__link {
  padding: 24px 0;
  width: 670px; }

.ortos-tabs {
  margin-top: 26px; }

.ortos-tab__link {
  width: 100%; }

@media (max-width: 470px) {
  .ortos-tab__content-item {
    width: 100%; }
  .ortos-tab__btn-wrapper {
    margin-top: 14px; }
  .ortos-tab__content-item.active {
    padding-bottom: 10px; }
  .ortos-tab__wrapper .title {
    margin-bottom: 8px; } }

.personal-form {
  width: 440px; }
  @media (max-width: 767px) {
    .personal-form {
      width: 100%; } }
  .personal-form > * + * {
    margin-top: 20px; }
  .personal-form > * + .personal-form__btn {
    margin-top: 24px; }
  .personal-form__btn {
    width: 100%; }
    .personal-form__btn[type="submit"] {
      display: none; }
  .personal-form__check {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 16px; }
    .personal-form__check.disabled {
      pointer-events: none; }

.input-label__card-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .input-label__card-wrapper span {
    margin-bottom: 0; }

.tooltip {
  width: 20px;
  height: 20px;
  background: url("../images/tooltip.svg") no-repeat;
  cursor: pointer;
  margin-left: 8px;
  position: relative; }

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  bottom: 135%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s; }

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

@media (max-width: 576px) {
  .tooltip .tooltiptext {
    width: 300px;
    left: -200%;
    padding: 10px; }
  .tooltip .tooltiptext::after {
    left: 36.5%; } }

a .personal-form__btn {
  margin-top: 16px !important; }

.personal-form__text {
  flex-basis: 100%;
  margin-left: 34px; }

.personal-form__field, .personal-form__field input, .personal-form__field .input-label, .date-input-container {
  width: 100%;
  display: block; }

.date-input-container {
  background-color: #f5f5f5;
  border-radius: 6px; }

.personal-form__field input {
  color: #2b2c2e; }

.personal-form__field input:disabled {
  opacity: .7; }

#popular {
  padding-top: 55px; }

.popular {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 1050px) {
    .popular {
      justify-content: space-around; } }

.popular-img__wrapper {
  max-width: 325px;
  width: 100%;
  height: 325px; }
  .popular-img__wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 6px; }

.popular-title {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2b2c2e;
  max-width: 380px; }

.popular-item {
  margin-bottom: 15px;
  transition: 0.35s; }

@media (min-width: 991px) {
  .popular-item:hover img {
    transform: scale(1.02);
    transition: 0.35s; } }

@media (max-width: 576px) {
  .popular-item {
    width: 45%; }
  .popular-img__wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative; }
  .popular-img__wrapper img {
    position: absolute; } }

.popular-img__wrapper img {
  transition: 0.35s; }

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background: rgba(43, 44, 46, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0s; }

.popup-menu.active {
  visibility: visible;
  transition: 0.8s;
  opacity: 1;
  transition: opacity 0s; }
  .popup-menu.active body {
    overflow: hidden; }
  .popup-menu.active .popup-menu__wrapper {
    transform: translate(0);
    transition: 0.8s; }

.popup-menu__wrapper {
  padding: 16px;
  width: 70%;
  background: #fff;
  height: 100vh;
  transform: translate(-100%);
  transition: 0.8s;
  overflow-y: scroll; }
  .popup-menu__wrapper.no-scroll {
    overflow: hidden; }

.popup-menu__head {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.menu-wrapper {
  display: flex;
  flex-direction: column; }

.mobile-menu__link {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e9e8ee;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #7F818C; }

.catalog-mobile {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-top: 32px; }

.catalog-mobile svg {
  margin-left: 30px; }

.mobile-menu__company {
  display: block; }

.mobile-menu__company svg {
  margin-left: 10px; }

.mobile-menu__geo {
  display: flex; }

.mobile-geo__text {
  margin-left: 8px; }

.company-submenu__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7F818C; }

.company-submenu {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden; }

.company-submenu__link {
  padding: 0 16px;
  margin-top: 16px; }

.mobile-menu__company svg {
  transition: 0.35s; }

.mobile-menu__company.active svg {
  transform: rotate(180deg);
  transition: 0.35s; }

.mobile-menu__company.active .company-submenu {
  height: auto;
  overflow: auto;
  transition: 0.35s; }

.catalog-mobile__wrapper {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  top: 85px;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 35;
  background-color: #fff;
  transition-duration: .6s;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 150px; }

.catalog-mobile__back {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e; }

.catalog-mobile__menu_link {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7F818C;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 0;
  width: 93%; }

.catalog-mobile__menu_link::after {
  content: '';
  position: absolute;
  right: 18px;
  transform: rotate(-135deg);
  border-left: 2px solid #959696;
  border-bottom: 2px solid #959696;
  width: 6px;
  height: 6px;
  transition: border .15s; }

.catalog-mobile__sub-menu_list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: translateX(-100%);
  transition: transform .25s;
  background-color: #fff;
  overflow-y: auto;
  overscroll-behavior-y: none;
  z-index: 3;
  padding: 16px;
  height: calc(100% - 86px); }

.catalog-mobile__menu_list-back {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2b2c2e;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9e8ee; }

.catalog-mobile__menu_list {
  list-style: none; }

.catalog-mobile__sub-menu_list {
  list-style: none; }

.catalog-mobile__menu_list-item a {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #7F818C;
  padding: 16px 0;
  width: 100%;
  display: block; }

.open-mobile-catalog .catalog-mobile__wrapper {
  opacity: 1;
  transform: translateZ(0); }

.catalog-mobile__menu_link.open + .catalog-mobile__sub-menu_list {
  transform: translateX(0); }

.catalog-mobile__menu_list-back svg {
  margin-right: 8px; }

@media (max-width: 576px) {
  .catalog-mobile__menu_link::after {
    position: absolute;
    margin-right: 4px;
    right: -20px; }
  .catalog-mobile__menu_link {
    justify-content: space-between; } }

@media (max-width: 416px) {
  .catalog-mobile__menu_link::after {
    position: absolute;
    right: -16px; }
  .catalog-mobile__menu_list-back svg {
    width: 35px;
    margin-right: 3px; } }

.catalog-mobile__menu_list-item {
  border-bottom: 1px solid #e9e8ee; }

.popup-size__wrapper {
  padding: 40px;
  padding-top: 20px;
  background: #fff;
  max-height: 90vh;
  aspect-ratio: 1 / 1; }
  .popup-size__wrapper .popup-cross img {
    width: 24px;
    height: 24px; }

.popup-size {
  display: flex;
  justify-content: center;
  align-items: center; }

.popup-size.active {
  visibility: visible;
  opacity: 1; }

@media (max-width: 576px) {
  .popup-size.active {
    padding: 12px; }
  .popup-size__wrapper {
    padding: 20px; } }

.modal-title__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }

.modal__title {
  margin-bottom: 0; }

#auth .modal__title {
  margin-bottom: 20px; }

.modal__back {
  width: initial; }

.modal__agree {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0 !important; }

.one-click__popup .modal__agree.modal__letter, .common__popup .modal__agree.modal__letter {
  padding-bottom: 30px; }

.modal__form .modal__letter {
  display: flex;
  align-items: flex-start; }

.popup-form .modal__letter {
  display: flex;
  align-items: flex-start; }
  .popup-form .modal__letter label {
    margin-bottom: 0 !important; }

.back_to-reg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ABABAB; }
  .back_to-reg .modal__back {
    transform: rotate(0);
    width: initial;
    height: initial;
    text-transform: uppercase;
    text-decoration: underline;
    color: #ABABAB;
    margin-left: 5px; }

.modal__form .btn {
  margin-top: 14px; }

.reviews-wrapper {
  padding-top: 30px;
  border-top: 1px solid #E9E8EE;
  margin-top: 30px; }

.reviews-controls {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #E9E8EE;
  border-bottom: 1px solid #E9E8EE;
  margin-top: 30px; }
  @media (max-width: 780px) {
    .reviews-controls {
      flex-direction: column;
      gap: 20px; } }
  .reviews-controls__box {
    display: flex;
    align-items: center;
    gap: 30px; }
    @media (max-width: 780px) {
      .reviews-controls__box {
        flex-direction: column;
        gap: 20px; } }
  .reviews-controls__show-all {
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer; }

.review-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E9E8EE; }
  .review-card:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }
  .review-card__stars {
    display: flex;
    align-items: center;
    gap: 2px; }
  .review-card__name {
    color: #2B2C2E;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%; }
  .review-card__date {
    color: #7F818C;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%; }
  .review-card__name-box {
    display: flex;
    flex-direction: column;
    gap: 8px; }
  .review-card__description {
    color: #2B2C2E;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%; }
  .review-card__details {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  .review-card__details-row {
    display: flex;
    gap: 6px; }
  .review-card__details-label {
    color: #7F818C;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
    flex-shrink: 0; }
  .review-card__details-value {
    color: #2B2C2E;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%; }

.review-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px; }
  @media (max-width: 780px) {
    .review-form {
      grid-template-columns: repeat(1, 1fr); } }
  @media (min-width: 780px) {
    .review-form__description-field {
      grid-column: span 2; } }
  .review-form__submit-btn {
    width: 100%; }
  .review-form__stars {
    display: flex;
    gap: 8px; }
  .review-form__star {
    cursor: pointer; }
    .review-form__star.is-active path {
      fill: #DBA300; }

.popup-review {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  overflow: auto; }
  @media (max-width: 780px) {
    .popup-review {
      align-items: flex-start;
      padding: 5vh 20px; } }
  .popup-review__wrapper {
    background: #fff;
    padding: 20px 40px 40px 40px;
    width: 100%;
    max-width: 980px; }

.popup-review.active {
  visibility: visible;
  opacity: 1; }

@media (max-width: 576px) {
  .popup-review.active {
    padding: 12px; }
  .popup-review__wrapper {
    padding: 20px; } }

#sale-goods {
  padding-top: 120px; }

.sale {
  background: #f16363;
  border-radius: 6px;
  padding: 6px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px; }

.goods-slide__price-wrapper {
  display: flex;
  align-items: center; }

.goods-slide__price {
  margin-right: 10px;
  user-select: none; }

.goods-slide__price-old {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #7F818C;
  text-decoration: line-through;
  margin-bottom: 22px; }

@media (max-width: 576px) {
  #special .title {
    width: 180px; } }

.shops-popup {
  padding-right: 16px; }
  @media (max-width: 576px) {
    .shops-popup {
      padding-right: 10px; } }
  .shops-popup__tabs {
    display: flex;
    margin-bottom: 30px;
    margin-top: 20px; }
    @media (max-width: 576px) {
      .shops-popup__tabs {
        margin-bottom: 36px; } }
  @media (max-width: 576px) {
    .shops-popup__tab {
      width: 50%; } }
  .shops-popup__map {
    height: 586px; }
    @media (max-width: 767px) {
      .shops-popup__map {
        height: 466px; } }

#special {
  padding-top: 120px; }

.video-popup.active {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1; }

.video-popup__wrapper {
  background: #fff;
  padding: 40px;
  position: relative;
  width: 80vw; }

.video-popup .mobile-cross {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2; }

.video-popup__title-wrapper {
  display: flex;
  margin-bottom: 30px; }

.video-popup__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  width: 90%; }

.mobile-cross:hover svg path {
  stroke: #dba300; }

@media (max-width: 991px) {
  .video.row-4 {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px; }
  .video-popup__title-wrapper {
    margin-bottom: 20px; }
  .video-title {
    font-size: 16px; } }

@media (max-width: 576px) {
  .video.row-4 {
    grid-template-columns: repeat(1, 1fr); } }

.eq_video {
  width: 100%;
  height: 50vh; }

.video-pagination ul {
  justify-content: center;
  margin-top: 6px; }

.video {
  margin-top: 50px; }

#video row-4 {
  gap: 20px 50px; }

.video-item {
  cursor: pointer; }

.video-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  margin-top: 20px; }

.video-wrapper iframe {
  display: none; }

@media (max-width: 1150px) {
  .video-popup__wrapper {
    width: 85vw; } }

@media (min-width: 991px) {
  .video-item:hover .video-title {
    color: #dba300; } }

#video .pagination ul {
  margin-top: 50px; }

@media (max-width: 576px) {
  .video-item img {
    width: 100%; }
  #video .pagination ul {
    margin-top: 30px; }
  .video-wrapper img {
    display: none; }
  .video-wrapper iframe {
    display: block;
    width: 100%;
    height: 192px; } }

@media (min-width: 1400px) {
  .video-wrapper img {
    height: 100%;
    border-radius: 6px; } }

@media (min-width: 991px) {
  .video-wrapper img {
    height: 100%;
    border-radius: 6px; } }

@media (min-width: 576px) {
  .video-wrapper img {
    height: 100%;
    border-radius: 6px; } }

@media (max-height: 450px) {
  .video-wrapper img {
    display: none; }
  .video-wrapper iframe {
    display: block; } }

@media (min-width: 576px) {
  .video-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 67.6%;
    position: relative; }
    .video-wrapper img {
      position: absolute;
      height: 100%;
      width: 100%; } }
