.title {
    font-weight: 500;
    font-size: 44px;
    text-transform: uppercase;
    color: $dark;

    @media(max-width: 576px) {
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 20px;
    }
}

h {

    &1,
    &2,
    &3,
    &4,
    &5,
    &6 {
        font-weight: 500;
        line-height: 140%;
    }
}

h1 {
    font-size: 44px;
    margin-bottom: 30px;
}

h2 {
    font-size: 24px;
}
