.fancy-popup.active {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  .details-good__slider-wrapper {
    position: static;
  }

  .mobile-cross img {
    width: 24px;
    height: 24px;
  }
}

.fancy-popup__wrapper {
  background: #fff;
  padding: 40px;
  position: relative;
  width: 80vw;
  height: 95vh;
  overflow: auto;
}

.popup-fancy__fancy-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fancy-popup .mobile-cross {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.fancy-popup__title-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.fancy-popup__title {
  font-weight: 500;
  font-size: 44px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2b2c2e;
  width: 90%;
}


.fancy-popup {
  .arrow-good__next {
    right: 0;
  }

  .arrow-good__prev {
    left: 0;
  }
}

@media (max-width: 991px) {
  .fancy.row-4 {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
  }

  .fancy-popup {
    .arrow-good__next {
      right: 0;
    }

    .arrow-good__prev {
      left: 0;
    }
  }

  .fancy-popup__title-wrapper {
    margin-bottom: 20px;
  }

  .fancy-title {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .fancy-popup__wrapper {
    padding: 20px;
    height: 75vh;

    .arrow-good {
      top: 33%;
    }
  }
}


@media (max-width: 576px) {
  .fancy.row-4 {
    grid-template-columns: repeat(1, 1fr);
  }

  .popup-fancy__fancy-wrapper {
    display: block;
  }

  .fancy-popup__title {
    font-size: 18px;
    line-height: 130%;
  }
}

.eq_fancy {
  width: 100%;
  height: 50vh;
}

.fancy-pagination ul {
  justify-content: center;
  margin-top: 6px;
}

.detail-good__slider_small_fancy .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
  cursor: pointer;
}

.detail-good__slider_small_fancy .swiper-slide-thumb-active {
  opacity: 1;
}

@media (max-width: 576px) {
  .fancy-popup__wrapper {
    height: 51vh !important;
  }
}

.fancybox__carousel .fancybox__slide .fancybox__content {
  max-width: 100%;
}

.carousel__button.fancybox__button--slideshow, .carousel__button.fancybox__button--fullscreen {
  display: none !important;
}