// colors
$dark: #2b2c2e;
$accent: #DBA300;
$yellow: #FFF0C4;
$green: #A2C871;
$red: #F95E5E;
$blue: #73A8F6;
$pink: #F076C7;
$orange: #F3B265;
$gray: #F5F5F5;
$gray-2: #7F818C;
$gray-3: #ABABAB;
$gray-4: #AAAAAA;

$brs: 6px;
