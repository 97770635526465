.personal-form {
    $this: &;

    width: 440px;

    @media(max-width: 767px) {
        width: 100%;
    }

    > * {
        & + * {
            margin-top: 20px;
        }

        & + #{$this}__btn {
            margin-top: 24px;
        }
    }

    &__btn {
        width: 100%;

        &[type="submit"] {
            display: none;
        }
    }

    &__check {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 16px;

      &.disabled {
        pointer-events: none;
      }
    }
}

.input-label__card-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      margin-bottom: 0;
    }
  }

  .tooltip {
    width: 20px;
    height: 20px;
    background: url('../images/tooltip.svg') no-repeat;
    cursor: pointer;
    margin-left: 8px;
    position: relative;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    bottom: 135%;
    left: 50%;
    margin-left: -60px;

    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  @media (max-width: 576px) {
    .tooltip .tooltiptext {
      width: 300px;
      left: -200%;
      padding: 10px;
    }

    .tooltip .tooltiptext::after {
      left: 36.5%;
    }
  }

a .personal-form__btn {
  margin-top: 16px !important;
}

.personal-form__text {
  flex-basis: 100%;
  margin-left: 34px;
}

.personal-form__field, .personal-form__field input, .personal-form__field .input-label, .date-input-container {
  width: 100%;
  display: block
}

.date-input-container {
  background-color: #f5f5f5;
  border-radius: 6px;
}

.personal-form__field input {
  color: #2b2c2e;
}

.personal-form__field input:disabled {
  opacity: .7;
}