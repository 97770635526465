.reviews-wrapper {
  padding-top: 30px;
  border-top: 1px solid #E9E8EE;
  margin-top: 30px;
}

.reviews-controls {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #E9E8EE;
  border-bottom: 1px solid #E9E8EE;
  margin-top: 30px;

  @media (max-width: 780px) {
    flex-direction: column;
    gap: 20px;
  }

  &__box {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 780px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__show-all {
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.review-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E9E8EE;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  &__stars {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__name {
    color: #2B2C2E;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }

  &__date {
    color: #7F818C;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
  }

  &__name-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__description {
    color:  #2B2C2E;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__details-row {
    display: flex;
    gap: 6px;
  }

  &__details-label {
    color:  #7F818C;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
    flex-shrink: 0;
  }

  &__details-value {
    color:  #2B2C2E;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
  }
}

.review-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__description-field {
    @media (min-width: 780px) {
      grid-column: span 2
    }
  }

  &__submit-btn {
    width: 100%;
  }

  &__stars {
    display: flex;
    gap: 8px;
  }

  &__star {
    cursor: pointer;

    &.is-active {
      path {
        fill: #DBA300;
      }
    }
  }
}


.popup-review {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  overflow: auto;

  @media (max-width: 780px) {
   align-items: flex-start;
   padding: 5vh 20px;
  }

  &__wrapper {
    background: #fff;
    padding: 20px 40px 40px 40px;
    width: 100%;
    max-width: 980px;
  }
}

.popup-review.active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 576px) {
  .popup-review.active {
    padding: 12px;
  }

  .popup-review__wrapper {
    padding: 20px;
  }
}