
.modal-title__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.modal__title {
  margin-bottom: 0;
}

#auth .modal__title {
  margin-bottom: 20px;
}

.modal__back {
  width: initial;
}

.modal__agree {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0 !important;
}

.one-click__popup .modal__agree.modal__letter,.common__popup .modal__agree.modal__letter{
  padding-bottom: 30px;
}

.modal__form .modal__letter {
  display: flex;
  align-items: flex-start;
}

.popup-form .modal__letter {
  display: flex;
  align-items: flex-start;

  label {
    margin-bottom: 0 !important;
  }
}

.back_to-reg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ABABAB;

  .modal__back {
    transform: rotate(0);
    width: initial;
    height: initial;
    text-transform: uppercase;
    text-decoration: underline;
    color: #ABABAB;
    margin-left: 5px;
  }
}

.modal__form .btn {
  margin-top: 14px;
}
