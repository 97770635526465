#banners {
    padding-top: 55px;
}

.banners {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 30px;

    @media (min-width: 991px) {
      overflow: visible;
    }
}

.banners-img {
    margin-top: 15px;
    border-radius: $brs;
    transition: 0.35s;

    @media(max-width: 576px) {
        height: 175px;
    }

    img {
        height: 100%;
        border-radius: $brs;
        width: 100%;

      @media (min-width: 1500px) {
        height: 360px;
        width: 100%;
      }
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $accent;
}

.swiper-pagination-bullet {
    background: #d8bd6e;
}

@media (min-width: 991px) {
    .banners-img:hover {
        transform: scale(1.03);
        transition: 0.35s;
    }
}
